import { Box, styled } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { GlobalContext } from '../../../../Context';
import { Badge, Button, IconButton, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { cartCountUpdate, getActiveCart, getCart, retailGetActiveCart } from '../../../../store/slices/accounts/cart/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const DraggableCart = () => {
    const winSize = useScreenSize();
    const theme = useTheme();
    const [maxPosition, setMaxPosition] = useState({ x: 77, y: 76 });
    const [position, setPosition] = useState({ x: window.innerWidth > 600 ? window.innerWidth - 100 : window.innerWidth - 79, y: 260 });
    const [timeStamp, setTimeStamp] = useState(0);
    const invertRef = useRef(null);
    const userDetails = useSelector(state => state.auth);
    const { cartCount } = useSelector(state => state.cartSlice);
    const isAuthenticated = userDetails.isAuthenticated;
    const dispatch = useDispatch();
    const refreshCartStatus = useSelector(state => state.commonReducer.Cart_Refresh);
    const navigate = useNavigate();
    const pathname = useLocation().pathname


    useEffect(() => {
        setPosition(state => ({
            ...state,
            x: winSize.screenSize.width > 600 ? (winSize.screenSize.width - 127) : (winSize.screenSize.width - 79)
        }));
    }, [winSize.screenSize.width]);

    useEffect(() => {
        if (isAuthenticated) {
            getActiveCartCall({ User_Email_Id: userDetails?.user?.Email_Id })
        }
    }, [isAuthenticated, refreshCartStatus]);

    useEffect(() => {
        if (isAuthenticated && invertRef.current) {
            const invertStyle = invertRef?.current?.style;
            if ((position.x < ((winSize.screenSize.width / 2) - (winSize.screenSize.width > 600 ? 127 : 79)))) {
                invertStyle.transitionDuration = ".5s";
                invertStyle.borderTopRightRadius = '50px';
                invertStyle.borderTopLeftRadius = '0px';
                invertStyle.borderBottomLeftRadius = '0px';
                invertStyle.borderBottomRightRadius = '50px';
            } else {
                invertStyle.transitionDuration = ".5s";
                invertStyle.borderTopLeftRadius = '50px';
                invertStyle.borderTopRightRadius = '0px';
                invertStyle.borderBottomLeftRadius = '50px';
                invertStyle.borderBottomRightRadius = '0px';
            }
        }
    }, [position.x]);



    const draggableCheck = (e) => {
        if (e.clientX < ((winSize.screenSize.width / 2) - (winSize.screenSize.width > 600 ? 127 : 79))) {
            if (e.clientY < maxPosition.y) {
                setPosition({ x: 0, y: 77 });
            } else if (e.clientY > (winSize.screenSize.height - 77)) {
                setPosition({ x: 0, y: winSize.screenSize.height - 97 });
            } else {
                setPosition({ x: 0, y: e.clientY });
            }
        } else {
            if ((e.clientY - 100) < maxPosition.y) {
                setPosition({ x: (winSize.screenSize.width - (winSize.screenSize.width > 600 ? 127 : 79)), y: 77 });
            } else if (e.clientY > (winSize.screenSize.height - 97)) {
                setPosition({ x: 0, y: (winSize.screenSize.height - 97) });
            } else {
                setPosition({ x: (winSize.screenSize.width - (winSize.screenSize.width > 600 ? 127 : 79)), y: e.clientY });
            }
        }
    };


    const getActiveCartCall = async (formData) => {
        let totalCartSize = 0
        const Rresponse = await retailGetActiveCart()
        if (Rresponse.status) {
            totalCartSize += Rresponse.data.length
        } else {
            totalCartSize += 0
        }
        // const Gresponse = await getActiveCart(formData)
        // if (Gresponse.status) {
        //     totalCartSize += false ? Gresponse.data.length : 0
        // } else {
        //     totalCartSize += 0
        // }

        dispatch(cartCountUpdate(totalCartSize))
    }

    if (!isAuthenticated) {
        return null;
    }
    if (cartCount > 0)
        return (
            <Box sx={{ position: "fixed", top: position.y, left: position.x, width: { md: '127px', sm: '127px', xs: '79px' }, height: { md: '82px', xs: '53px', sm: '82px' }, zIndex: 999 }}>
                <Draggable
                    defaultPosition={{ x: winSize.screenSize.width - (winSize.screenSize.width > 600 ? 100 : 79), y: 76 }}
                    position={position}
                    touchEnabled={true}
                    onClick={() => {
                        navigate(`/${(pathname.includes("retail") || pathname.includes("accounts")) ? "retail" : "grocery"}/cart`);
                    }}
                    onDrag={(e) => {
                        const immediate = setTimeout(() => {
                            if (e?.changedTouches?.length > 0) {
                                const nativeEvent = e.targetTouches[0];
                                setPosition({ x: (nativeEvent.clientX - (winSize.screenSize.width > 600 ? 127 : 79) / 2), y: (nativeEvent.clientY) });
                            } else {
                                setPosition({ x: (e.clientX - (winSize.screenSize.width > 600 ? 127 : 79) / 2), y: (e.clientY) });
                            }
                        }, 100);
                        return () => clearTimeout(immediate);
                    }}
                    onStop={(e) => {
                        const immediate = setTimeout(() => {
                            if (e?.changedTouches?.length > 0) {
                                const nativeEvent = e.changedTouches[0];
                                draggableCheck(nativeEvent);
                            } else {
                                draggableCheck(e);
                            }
                        }, 100);
                        return () => clearTimeout(immediate);
                    }}
                    nodeRef={invertRef}
                >
                    <Box
                        onClick={() => {
                            navigate(`/${((pathname.includes("retail") || pathname.includes("accounts")) && !(pathname.includes("accounts") && pathname.includes("grocery"))) ? "retail" : "grocery"}/cart`);
                        }}
                        onTouchStart={(e) => {
                            setTimeStamp(e.timeStamp);
                        }}
                        onTouchEndCapture={(e) => {
                            if ((e.timeStamp - timeStamp) < 100) {
                                navigate(`/${((pathname.includes("retail") || pathname.includes("accounts")) && !(pathname.includes("accounts") && pathname.includes("grocery"))) ? "retail" : "grocery"}/cart`);
                            }
                        }}
                        ref={invertRef}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: { md: '127px', sm: '127px', xs: '79px' },
                            height: { md: '82px', xs: '53px', sm: '82px' },
                            backgroundColor: theme.palette.primary.main,
                            borderTopLeftRadius: '50px',
                            borderBottomLeftRadius: '50px',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            transform: 'rotate(90deg)',
                            cursor: 'pointer',
                            color: "white",
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                            }
                        }}
                        color='inherit'
                    >
                        <ShoppingCartIcon sx={{ marginRight: '8px', fontSize: { xs: '12px', sm: '24px', md: '24px' } }} />
                        <Typography sx={{ fontSize: { sm: '16px', xs: '10px', md: '16px' }, fontWeight: 'bold', marginRight: '4px' }}>
                            {cartCount}
                        </Typography>
                        <Typography sx={{ fontSize: { sm: '16px', xs: '10px', md: '16px' }, fontWeight: 'bold' }}>cart</Typography>
                    </Box>
                </Draggable>
            </Box>
        );
    else {
        return (
            <Box ></Box>
        )
    }
};

export default DraggableCart;
