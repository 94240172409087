import CryptoJS from 'crypto-js';
import axios from 'axios';
import Base64 from '../../utils/Base64';

const {
    REACT_APP_BOOMI_HOST,
    REACT_APP_USERNAME_BOOMI,
    REACT_APP_PASSWORD_BOOMI,
    REACT_APP_NODE_USER_HOST,
    REACT_APP_NODE_CUD_HOST,
    REACT_APP_BASEURL,
    REACT_APP_API_KEY,
    REACT_APP_NODE_ENV,
    REACT_APP_GOOGLE_MAP_API_KEY,
    REACT_APP_GOOGLE_MAP_WEB_API_KEY,
    REACT_APP_CRYPTO_KEY
} = process.env;


const envConfig = {
    BOOMI_HOST: REACT_APP_BOOMI_HOST,
    USERNAME_BOOMI: REACT_APP_USERNAME_BOOMI,
    PASSWORD_BOOMI: REACT_APP_PASSWORD_BOOMI,
    NODE_USER_HOST: REACT_APP_NODE_USER_HOST,
    NODE_CUD_HOST: REACT_APP_NODE_CUD_HOST,
    CRYPTO_KEY: REACT_APP_CRYPTO_KEY,
    GOOGLE_MAP_API_KEY: REACT_APP_GOOGLE_MAP_API_KEY,
    GOOGLE_MAP_WEB_API_KEY: REACT_APP_GOOGLE_MAP_WEB_API_KEY,
    BASEURL: REACT_APP_BASEURL,
    API_KEY: REACT_APP_API_KEY
}

const nodeApi = axios.create({
    baseURL: envConfig.NODE_USER_HOST,
    timeout: 100000, // Adjust as needed
});

// Add a request interceptor
nodeApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, envConfig.CRYPTO_KEY);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }

        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeCudApi = axios.create({
    baseURL: envConfig.NODE_CUD_HOST,
    timeout: 100000, // Adjust as needed
});

// Add a request interceptor
nodeCudApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, envConfig.CRYPTO_KEY);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }

        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const boomiApi = axios.create({
    baseURL: envConfig.BOOMI_HOST,
    headers: {
        "Content-Type": "application/json",
        'Authorization': "Basic " + Base64.btoa(envConfig.USERNAME_BOOMI + ":" + envConfig.PASSWORD_BOOMI)
    }
});

// Add a request interceptor
boomiApi.interceptors.request.use(
    async (config) => {
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, envConfig.CRYPTO_KEY);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}

export { nodeApi, nodeCudApi, boomiApi, envConfig, urlGenarator }