import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nodeApi, urlGenarator } from "../../CommonAxios";

export const getPartnersAction = createAsyncThunk(
  'products/PatnerFilter',
  async (apidata, thunkApi) => {
    try {
      const response = await nodeApi.post(urlGenarator('/Partner/Get_partner', apidata.pagination), apidata.formData);
      const data = response.data;

      if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
      } else {
        return { success: true, results: data.results, pagination: data.Pagination };
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ error: error.message });
    }
  }
);

export const getBrandAction = createAsyncThunk('products/BrandsFilter', async (apidata, thunkApi) => {
  try {
    const response = await nodeApi.post(urlGenarator('/Home/Brand/Get', apidata?.pagination || {}), apidata?.formData || {});
    const data = response.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
      return [];
    } else {
      return { success: true, results: data.results, pagination: data.Pagination };
    }
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
})
export const getCategoreyAction = createAsyncThunk('products/CategoreyFilter', async (apidata, thunkApi) => {

  try {
    const response = await nodeApi.post(urlGenarator('/Home/Categories/Get', apidata?.pagination || {}), apidata?.formData || {});
    const data = response.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
      return [];
    } else {
      return { success: true, results: data.results, pagination: data.Pagination };
    }
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
})
export const getSubCategoriesAction = createAsyncThunk('products/SubCategoriesFilter', async (apidata = { formData: { Records_Filter: "All" }, pagination: { PageNo: 1, PageSize: 1000, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" } }, thunkApi) => {
  try {
    const response = await nodeApi.post(urlGenarator('/Home/SubCategories/Get', apidata.pagination), apidata.formData);
    const data = response.data;

    if (data.Success_Response.Is_Data_Exist === "0") {
      return [];
    } else {
      return { success: true, results: data.results, pagination: data.Pagination };
    }
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
})

export const ProductFilterSlice = createSlice({
  name: "ProductFilter",
  initialState: {
    partners: [],
    brands: [],
    category: [],
    subcategory: [],
  },
  reducers: {
    subToCat: (state, action) => {
      state.category = [{ id: action.payload.id, title: action.payload.title }]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartnersAction.pending, (state, action) => {
      state.partners = []
    }).addCase(getPartnersAction.fulfilled, (state, action) => {
      state.partners = action.payload.results.map((partner, index) => ({ id: partner.Partner_Details_Id, title: partner.Name }));
    }).addCase(getPartnersAction.rejected, (state, action) => {
      state.partners = []
    }).addCase(getBrandAction.pending, (state, action) => {
      state.brands = []
    }).addCase(getBrandAction.fulfilled, (state, action) => {
      state.brands = action.payload.results?.map((brand, index) => ({ id: brand.Brand_Id, title: brand.Brand_Name }))
    }).addCase(getBrandAction.rejected, (state, action) => {
      state.brands = []
    }).addCase(getCategoreyAction.pending, (state, action) => {
      state.category = []
    }).addCase(getCategoreyAction.fulfilled, (state, action) => {
      state.category = action.payload.results?.map((cate, index) => ({ id: cate.Category_Id, title: cate.Category_Name }))
    }).addCase(getCategoreyAction.rejected, (state, action) => {
      state.category = []
    }).addCase(getSubCategoriesAction.pending, (state, action) => {
      state.subcategory = []
    }).addCase(getSubCategoriesAction.fulfilled, (state, action) => {
      state.subcategory = action.payload.results?.map((subcate, index) => ({ id: subcate.Sub_Category_Id, title: subcate.Sub_Category_Name, cid: subcate.Category_Id, ctitle: subcate.Category_Name }))
    }).addCase(getSubCategoriesAction.rejected, (state, action) => {
      state.subcategory = []
    })
  }
})

export const { subToCat } = ProductFilterSlice.actions