import { Autocomplete, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBrandAction } from '../../../../store/slices/retail/home/ProductFiltersSlice'
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice'
import { useLocation } from 'react-router-dom'

const BrandsFilter = () => {
    const dispatch = useDispatch()
    const brands = useSelector(state => state.productFilter.brands);
    const { filters: { Brand_Id }, loading } = useSelector((state) => state.allProducts);
    const [selected, setSelected] = useState(null);
    const pathname = useLocation().pathname

    useEffect(() => {
        dispatch(getBrandAction(
            {
                formData: { Is_Retail: pathname.includes("retail") ? 1 : 0, Is_Grocery: pathname.includes("retail") ? 0 : 1 }, pagination: { PageNo: 1, PageSize: 1000, SortBy: "BRAND_NAME", SortOrder: "ASC" }
            }
        ))
    }, [])

    return (
        <Box sx={{ width: "100%", margin: "10px 0px" }}>
            <Autocomplete
                disablePortal
                key={"brandFilter"}
                options={brands || []}
                multiple
                limitTags={1}
                sx={{
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}
                value={Brand_Id}
                defaultValue={Brand_Id}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField  {...params} label="Search Brands"  InputLabelProps={{
                    style: {
                      fontWeight: 'bold'
                    }
                  }}/>}
                renderOption={(props, option) => (
              
                    <FormControlLabel
                    {...props}
                    sx={{ width: "98%" }}
                    control={<Checkbox checked={props["aria-selected"]} />}
                    label={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {option.title}
                      </Typography>
                    }
                  />
                )}
                onChange={(event, newValue) => {
                    dispatch(AllProductsActions.updateFilter({ Brand_Id: newValue }))

                }}
            />
        </Box>


    )

}
export default BrandsFilter
