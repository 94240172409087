import * as React from "react";
import { Box, Button, Grid, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveCart, getCart, getRetailActiveCart, placeOrderErrorShow, placeOrderPartnerReady, retailPlaceOrderErrorShow, retailPlaceOrderPartnerReady } from "../../../store/slices/accounts/cart/CartSlice";
import CartPartner from "./CartPartner";
import { getUserSettings } from "../../../store/slices/AuthSlice";
import { fetchAddress } from "../../../store/slices/accounts/Address/Address";
import cartIsEmpty from '../../../assests/images/cartIsEmpty.png'
import cartInactive from '../../../../src/assests/images/cartInactive.png';
import cartActive from '../../../../src/assests/images/CartActive.png';
import useScreenSize from "../../../utils/ScreenWidthHight";
import { GlobalContext } from '../../../Context'
import CartProductDetails from "./CartProductDetails";
const Cart = ({ Type = "RETAIL" }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const { data: carts, isLoading, error } = useSelector(state => state.cartSlice.Retail_Cart);
    const [cartProducts, setCartProducts] = useState([])
    const userDetails = useSelector(state => state.auth)
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const winSize = useScreenSize()
    const [fgPartnerOrderRequiredAmount, setFGPartnerOrderRequiredAmount] = React.useState(150);
    const isAuthenticated = userDetails.isAuthenticated;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getRetailActiveCart())
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (userDetails?.isAuthenticated)
            addressFetch()
        else
            navigate("/auth/signin")
    }, [])

    useEffect(() => {
        (async () => {
            const res = await getUserSettings("CART_DELIVERY_CHARGE_BALANCER", false);
            if (res.status)
                setFGPartnerOrderRequiredAmount(res.data);
            setLoading(false);
        })()
    }, [])

    const addressFetch = (page = 1, pagesize = 50) => {
        dispatch(fetchAddress({
            formData: {
                "User_Email_Id": userDetails?.user?.Email_Id
            },
            pagination: { PageNo: page, PageSize: pagesize }
        }))
    }

    const varifyCart = (partnerId) => {
        let error = false;
        const partner = carts.filter((data) => data.Partner_Details_Id == partnerId)[0];
        const partnerAddressCheck = partner.Business_Type == "GROCERY" ? true : false;
        if (partnerAddressCheck) {
            if (!partner.User_Shipping_Address_Id) {
                error = true
            }
        }
        if (!error)
            for (let i = 0; i < partner.Products.length; i++) {
                let product = partner.Products[i];
                if (!(product.Quantity <= product.Availability_Stock)) {
                    error = true;
                    break;
                }

                if (!(product.User_Shipping_Address_Id) && partner.Business_Type != "GROCERY") {
                    error = true;
                    break;
                }
            }

        if (error) {
            dispatch(retailPlaceOrderErrorShow(partner.Partner_Details_Id))
        } else {
            dispatch(retailPlaceOrderPartnerReady(partner.Partner_Details_Id))
        }
        return error
    }

    const placeOrder = (partner) => {
        let noError = true
        if (partner == "ALL") {
            for (let i = 0; i < carts.length; i++) {
                let error = varifyCart(carts[i].Partner_Details_Id);
                if (noError) {
                    noError = !error
                }

            }
        } else {
            noError = !varifyCart(partner);
        }

        if (noError) {
            navigate(`/retail/checkout/${partner}?Cart=Retail_Cart`)
        }
    }

    const getActiveCartCall = async (apiurl, formData) => {
        const response = await getActiveCart(apiurl)
        if (response.status) {
            setCartProducts(response.data)
        } else {
            setCartProducts([])
        }
    }


    const getPartnerOrderPrice = (items) => {
        let amount = 0;
        items.Products.map(product => (
            amount = amount + (Number(product.Partner_Selling_Price) * Number(product.Quantity))
        ));
        return amount.toFixed(2);
    };

    const deliveryValueCaluculation = (orderValue, arrayData) => {
        let response = { charge: 0, message: "" };
        function sortResults(input, prop, asc) {
            input.sort(function (a, b) {
                if (a[prop]) {
                    if (asc) {
                        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                    } else {
                        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                    }
                } else {
                    throw new Error(prop + ' is not a exist!');
                }
            });
            return input;
        }
        try {
            let data = sortResults(JSON.parse(arrayData), "value", true);
            for (let i = 0; i < data.length; i++) {
                if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
                    let message = "";
                    let charge = 0;
                    if (i == 0 || i == data.length - 1) {
                        charge = data[i].charge
                    }
                    else {
                        charge = data[i - 1].charge
                    }
                    if (orderValue < data[i].value && i < data.length - 1) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                            else
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
                        }
                    } else if (orderValue < data[i].value) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                        }
                    }
                    response = { charge: charge, message: message };
                    break;
                }
            }
        } catch (error) {
        }
        return response
    }

    const getDeliveryPrice = (items) => {
        let extraCharge = 0;
        let amount = 0;
        items.Products.map(product => {
            amount = amount + (Number(product.Shipping_Amount ? product.Shipping_Amount : 0) * Number(product.Quantity))
        });
        if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
            extraCharge = deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount).charge;
        }
        return (amount + extraCharge).toFixed(2);
    };

    const totalPrices = React.useMemo(() => {
        let totalPrice = 0;
        carts.map((partner) => {
            (partner.Products || []).forEach(item => {
                totalPrice += item.Partner_Selling_Price * item.Quantity;
            });
            totalPrice += Number(getDeliveryPrice(partner));
        })
        return parseFloat(totalPrice.toFixed(1));
    }, [carts]);
    const Cart_Products = React.useMemo(() => {
        let products = []
        carts.forEach((cart) => {
            products = [...products, ...cart.Products]
        })
        return products
    }, [carts])
    if (carts.length > 0 || loading) {
        return (
            <Grid container sx={{ minHeight: "100vh" }}>
                <Grid item xs={12} md={3.5} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 3, }}>
                        <Typography sx={{ fontSize: '24px', color: theme.palette.text.secondary, textTransform: "capitalize" }}> Retail Cart</Typography>
                        {winSize.screenSize.width < 600 &&
                            <Box sx={{ display: 'flex', }}>
                                {loading ? (
                                    <Skeleton variant="rectangular" width={150} height={36} />
                                ) : (
                                    carts.length > 0 && (
                                        <Button onClick={() => placeOrder("ALL")} variant="contained">PLACE ALL ORDERS</Button>
                                    )
                                )}
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ minHeight: "100vh" }}>
                    {winSize.screenSize.width > 600 &&
                        <Box sx={{ display: 'flex', justifyContent: 'right', margin: 3 }}>
                            {loading ? (
                                <Skeleton variant="rectangular" width={150} height={36} />
                            ) :
                                <Box sx={{ display: "flex", width: { xs: "100%", sm: "20%" }, justifyContent: "space-between" ,alignItems:"center"}} >
                                    {loading ? (
                                        <>
                                            <Skeleton variant="text" width={150} height={30} />
                                            <Skeleton variant="rectangular" width={150} height={36} />
                                        </>
                                    ) : (
                                        <>
                                            <Box >
                                                <Typography sx={{ fontWeight: "bold" }}>Total &#8377; {totalPrices}</Typography>
                                            </Box>
                                        </>
                                    )}
                                    {(carts.length > 0 && (
                                        <Button onClick={() => placeOrder("ALL")} variant="contained">PLACE ALL ORDERS</Button>
                                    ))
                                    }
                                </Box>

                            }
                        </Box>
                    }
                    <Grid display="flex" flexWrap="wrap" >
                        {
                            Cart_Products &&
                            Cart_Products.map((cart, index) => {
                                return (
                                    <CartProductDetails
                                        getActiveCartCall={getActiveCartCall}
                                        key={index}
                                        product={cart}
                                    // partner={partner}
                                    />)
                            })
                        }
                    </Grid>

                </Grid>
            </Grid >
        );
    } else {
        return (
            <Box sx={{ width: "100%", height: "100%" }} >
                <Typography sx={{ fontSize: '24px', color: theme.palette.text.secondary, ml: '30px', textTransform: "capitalize" }}>Retail Cart</Typography>
                {carts.length === 0 &&
                    <Box sx={{ margin: 3, }}>
                        <img
                            src={cartIsEmpty}
                            alt="cartIsEmpty" style={{ display: "block", width: '40%', height: '37%', margin: 'auto', objectFit: 'contain', justifyContent: 'center', alignItems: 'center' }} ></img>
                    </Box>
                }
            </Box>
        )
    }
}
export default Cart

