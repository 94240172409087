import { Box } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../../../Context'
import Cart from '../cart/Cart'
const NewCart = () => {
    const tabDotRef = useRef([])
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);
    const [selectedTab, setSelectedTab] = useState()
    useEffect(() => {
        if (searchParams.get("Tab")) {
            const tab = searchParams.get("Tab")
            if (tab?.toUpperCase() === "Retail".toUpperCase()) {
                tabChangeAnimation(1, 0)
            } else if (tab?.toUpperCase() === "grocery".toUpperCase()) {
                tabChangeAnimation(0, 1)
            }
            else {
                updateQueryParam([{ "Tab": "Retail" }])
            }
        }
        else {
            updateQueryParam([{ "Tab": "Retail" }])
        }
    }, [searchParams])
    const tabChangeAnimation = (from = 0, to = 1) => {
        if (tabDotRef.current[from] && tabDotRef.current[to] && selectedTab !== to) {
            const fromStyles = tabDotRef.current[from].style
            const toStyles = tabDotRef.current[to].style
            fromStyles.transitionDuration = ".4s"
            fromStyles.height = "90px"
            fromStyles.width = "20px"
            fromStyles.borderRadius = "5px"
            toStyles.transitionDuration = ".4s"
            toStyles.height = "10px"
            toStyles.width = "10px"
            setTimeout(() => {
                fromStyles.transitionDuration = ".3s"
                fromStyles.height = "20px"
                fromStyles.width = "20px"
                fromStyles.borderRadius = "50%"
                toStyles.transitionDuration = ".3s"
                toStyles.height = "30px"
                toStyles.width = "30px"
                if (to === 1) {
                    updateQueryParam([{ "Tab": "grocery" }])
                    setSelectedTab(to)
                }
                else {
                    updateQueryParam([{ "Tab": "retail" }])
                    setSelectedTab(to)
                }
            }, 700)
        }
    }

    return (
        <Box sx={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center" }} >
            <Box sx={{ width: "100%", height: "100vh", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                <Box sx={{ width: "5%", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                    <TabDot size='20px' color='#27b6cc' innerRef={(el) => tabDotRef.current[0] = el} onClick={() => tabChangeAnimation(1, 0)} />
                    <TabDot size='20px' color='green' innerRef={(el) => tabDotRef.current[1] = el} onClick={() => tabChangeAnimation(0, 1)} />
                </Box>
                <Box sx={{ width: "90%", height: "100%",overflowY:"scroll" }} >
                    {
                        selectedTab === 0 &&
                        <Cart Type='Retail' />
                    }
                    {
                        selectedTab === 1 &&
                        <Cart Type='grocery' />
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default NewCart

const TabDot = ({ size = "20px", color = "", innerRef, onClick = (() => { }) }) => {
    return (
        <Box
            sx={{ width: size, height: size, borderRadius: "50%", backgroundColor: color, marginY: "15px", cursor: "pointer" }}
            ref={el => innerRef(el)}
            onClick={onClick}
        >

        </Box>
    )
}