import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Table, TableCell, TableRow, Typography } from '@mui/material'
import { Nibuspost_Order_History, productFetch } from '../../../../store/slices/accounts/orders/OrdersSlice';
import AutoAdjustText from '../../../../utils/AutoAdjustText';
import { Step, StepLabel, Stepper } from '@mui/material';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { isEmpty } from '../../../../utils';
import { Done, HourglassEmpty } from '@mui/icons-material';

const OrderStepper = ({ status, Line_Item_Order_Status }) => {
    // Define all possible statuses
    Line_Item_Order_Status = Line_Item_Order_Status === "ORDERED" ? "NEW" : Line_Item_Order_Status
    const allStatuses = ['NEW', 'CANCELLED', 'RETURN', 'Settled'];
    const winsize = useScreenSize()
    // If status is CANCELLED or RETURN, use the custom set of statuses
    let statusesToShow = ['NEW',"ACCEPTED", 'SHIPPED', 'OUT-FOR-DELIVERY', 'DELIVERED'];

    if (status === 'CANCELLED' || Line_Item_Order_Status ==="Settled".toUpperCase()) {
        statusesToShow = ['NEW', 'CANCELLED', 'Settled'.toUpperCase()];
    } else if (Line_Item_Order_Status === 'RETURN' && status === "Settled".toUpperCase() ) {
        statusesToShow = ['NEW', 'RETURN', 'Settled'];
    }

    // Get index of the current status
    const currentStatusIndex = statusesToShow.indexOf(Line_Item_Order_Status);
    // Determine completed steps based on current status index
    const completedSteps = statusesToShow.slice(0, currentStatusIndex + 1);
    
    // Map statuses to step labels
    const stepLabels = statusesToShow.map((Line_Item_Order_Status, index) => (
        <Step key={index} completed={completedSteps.includes(Line_Item_Order_Status)}>
            <StepLabel>{Line_Item_Order_Status}</StepLabel>
        </Step>
    ));
    if(winsize.screenSize.width>600)
    return (
        <Stepper   activeStep={currentStatusIndex} alternativeLabel orientation={winsize.screenSize.width < 600 ? "horizontal" : "horizontal"}
        >
            {stepLabels}
        </Stepper>
    )
    else {
        return (
            <Box sx={{width:"100%",height:"50px",}} >
            <Stepper
                    sx={{ position: "absolute",left:"0",transform:"scale(90%)" }}
                activeStep={currentStatusIndex} alternativeLabel orientation={winsize.screenSize.width < 600 ? "horizontal" : "horizontal"}
            >
                {stepLabels}
                </Stepper>
            </Box>
        )
    }
};

export default OrderStepper;


export const Order_Stepper = ({ Order_Labels = [], Active_Label = 0, Connecter_Width = {}, OrderHistory = {}, Color }) => {
    const [selectedHistory, setSelectedHistory] = useState(Order_Labels[Active_Label])
    useEffect(() => {
        if (Order_Labels && Active_Label) {
            setSelectedHistory(Order_Labels[Active_Label])
        }
    }, [Order_Labels, Active_Label])
    return (
        <Box sx={{ width: "100%", height: "auto", display: "flex", flexDirection: "column", rowGap: "30px" }} >
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", marginTop: "100px" }} >
                {
                    Order_Labels.map((value, index) => {
                        const isActive = (Active_Label >= index)
                        const label = findAndReplaceAll(value, "_", " ")
                        return (
                            <Render_Stepper Active_Label={Active_Label} value={value} Order_Labels={Order_Labels} index={index} isActive={isActive} label={label} key={index} Connecter_Width={Connecter_Width} setSelectedHistory={setSelectedHistory} Color={Color} />
                        )
                    })
                }

            </Box>
            {(OrderHistory?.history && OrderHistory?.history?.length > 0 && ["READY_FOR_SHIPPING", "SHIPPED"].includes(Order_Labels[Active_Label])) &&
                <Box sx={{ width: "100%", height: "auto", marginBottom: "30px", transitionDuration: "1s", }} >
                    {(OrderHistory?.history && OrderHistory?.history?.length > 0) && <Typography>Your item has been picked up by courier partner.</Typography>}
                    <Table>
                        {
                            OrderHistory?.history?.map((history, index) => {
                                return (
                                    <TableRow >
                                        <TableCell >
                                            <Typography sx={{ fontSize: ".8rem", }} >{history.location}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: ".8rem", paddingX: 2 }} >{history.event_time}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: ".8rem", paddingX: 2 }} >{history.message}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })

                        }
                    </Table>
                </Box>
            }
        </Box>
    )
}

const findAndReplaceAll = (string, searchString, replaceString) => {
    const OrginalString = String(string)
    let NewString = ""
    if (OrginalString) {
        for (let i = 0; i < OrginalString.length; i++) {
            if (OrginalString[i] === searchString) {
                NewString += replaceString
            } else {
                NewString += OrginalString[i]
            }
        }
    } else {
        NewString = OrginalString
    }
    return NewString

}



const Render_Stepper = ({ Active_Label, value, isActive, index, Order_Labels, label, Connecter_Width = {}, setSelectedHistory, Color }) => {
    const progressRef = useRef(null); // Create a ref for the progress bar
    const labelHeight = useRef([]); // Create a ref for the progress bar
    const [aniActive, setAniActive] = useState(false)
    const winSize = useScreenSize()
    useEffect(() => {
        const timeOut = index === 0 ? 1000 : ((index + 1) * 1000)
        setAniActive(false)
        if (isActive && progressRef.current && !isEmpty(Connecter_Width)) {
            const progressStyles = progressRef.current.style
            progressStyles.width = "0px";
            progressStyles.transitionDuration = ".2s"
            setTimeout(() => {
                setAniActive(true)
                progressStyles.backgroundColor = Color
                progressStyles.transitionDuration = "1s"
                progressStyles.width = Connecter_Width.connecterWidth + "px";
            }, timeOut)

        } else if (progressRef.current) {
            progressRef.current.style.width = "0px"; // Reset width for inactive steps
        } else {
            setTimeout(() => {
                setAniActive(label === Order_Labels[Active_Label])
            }, timeOut)
        }
    }, [isActive, Connecter_Width]); // Trigger this effect when isActive changes
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                {/* Circle with icon */}
                <Box
                    sx={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: aniActive ? Color : "grey",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: aniActive ? "pointer" : "default"
                    }}
                    onClick={() => {
                        setSelectedHistory(value)
                    }}
                >
                    {aniActive ? (
                        <Done fontSize="small" sx={{ color: "white" }} />
                    ) : (
                        <HourglassEmpty fontSize="small" sx={{ color: "white" }} />
                    )}
                </Box>

                {/* Progress Line */}
                {index !== Order_Labels.length - 1 && (
                    <Box
                        sx={{
                            width: Connecter_Width.connecterWidth,
                            height: "2px",
                            backgroundColor: "grey",
                            position: "relative",
                            overflow: "hidden",
                        }}
                    >
                        {/* Dynamic progress bar */}
                        <Box
                            ref={progressRef} // Attach the ref here
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                width: 0,
                                backgroundColor: Color,
                            }}
                        />
                    </Box>
                )}

                {/* Label Box */}
                <Box
                    sx={{
                        position: "absolute",
                        top: - (35 + (labelHeight?.current["label"]?.getBoundingClientRect()?.height / 2)),
                        left: winSize.screenSize.width < 600 ? -(Connecter_Width.connecterWidth / 2.5) : -(Connecter_Width.connecterWidth / 2.1),
                        width: winSize.screenSize.width < 600 ? (Connecter_Width.connecterWidth) : Connecter_Width.connecterWidth,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: labelHeight?.current["label"]?.getBoundingClientRect()?.height
                    }}
                >
                    <Box
                        sx={{ width: "auto", height: "auto" }}
                    >
                        <Typography
                            style={{ textAlign: "center", width: "100%", fontSize: winSize.screenSize.width < 600 ? ".6rem" : ".8rem", color: aniActive ? Color : "black", transitionDuration: "1s" }}
                            ref={el => labelHeight.current["label"] = el}
                        >
                            {label}
                        </Typography>
                    </Box>

                </Box>

            </Box>
        </Box>
    );
};