import axios from "axios"
import { nodeApi, nodeCudApi, urlGenarator } from "../../CommonAxios"


export const imagesGetByProductId = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post("/Products/Product_Image_Get", formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results[0] })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const colorVariantByProductId = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post("/Products/Product_Color_Variant", formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const sizeGetByProductId = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post("/Products/Different_Size_Get", formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const retailSizeGetByProductId = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post("/Products/Retail_Different_Size_Get", formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const productDetailByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeApi.post('/Products/GetProducts', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const partnerDetailByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeApi.post('/Products/Single_Partner_Products_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const productReviewByProductId = (formData, Pagination = {}) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeApi.post(urlGenarator('/Reviews/Product_Review_Get', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const productReviewImagesGet = (formData, Pagination = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Reviews/Review_Image_Get', Pagination), formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results, Pagination: res.data.Pagination })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const updateProductReview = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post("/Reviews/Review_Image_Create", formData).then((res) => {
            if (res.data) {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false })
            }
        }).catch((err) => {
            resolve({ status: false })
        })
    })
}

export const productGetReviewExpression = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeApi.post('/Reviews/Expression_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const productCreateReviewExpression = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeCudApi.post('/Reviews/Expression_Create', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true })
            }
            else {
                resolve({ status: false })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const productOffersGet = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeApi.post('/Products/Offer_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const catergoryNameGetFromProductID = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Products/Single_Partner_Products_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results[0].Category_Name })
            }
            else {
                resolve({ status: true, data: "" })

            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const createProductReviewByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeCudApi.post('/Reviews/Product_Review_Create', formData).then((res) => {
            if (res.data.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const updateImageProductReviewByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeCudApi.post('/Reviews/Review_Image_Create', formData).then((res) => {
            if (res.data.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const updateProductReviewByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeCudApi.post('/Reviews/Product_Review_Update', formData).then((res) => {
            if (res.data.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const imageDeleteProductReviewByProductId = (formData) => {
    return new Promise(async (resolve, rejcet) => {
        await nodeCudApi.post('/Reviews/Review_Image_Delete', formData).then((res) => {
            if (res.data.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const cartUpdateAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Cart/Update', formData).then((res) => {
            if (res.data.Response_Code === "200") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const cartProductDeleteAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Cart/Delete', formData).then((res) => {
            if (res.data.Response_Status === 'Success')
                resolve({ status: true, data: res.data })
            else
                resolve({ status: false, error: "failed" })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const cartProductCreateAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/Cart/Create', formData).then((res) => {
            if (res.data.Response_Code === "200")
                resolve({ status: true, data: res.data })
            else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => { resolve({ status: false, error: err.message }) })
    })
}

export const productCheckCartAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Home/user_cart/check', formData).then((res) => {
            if (res.data?.User_Cart_Id) {
                resolve({ status: true, data: res.data })
            } else {
                resolve({ status: false, error: {} })
            }
        }).catch(err => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const retailProductCheckCartAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Cart/Retail-Check', formData).then((res) => {
            if (res.data?.User_Cart_Id) {
                resolve({ status: true, data: res.data })
            } else {
                resolve({ status: false, error: {} })
            }
        }).catch(err => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const productWishCheckAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/User_Wishlist/Icon_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }

        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const productWishCreateAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/User_Wishlist/Create', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
        }).catch((err) => {
            resolve({ status: false })
        })
    })
}
export const productWishDeleteAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post('/User_Wishlist/Delete', formData).then((res) => {
            if (res.data.Response_Status === "Success")
                resolve({ status: true, data: res.data })
        }).catch((err) => {
            resolve({ status: false })
        })
    })
}

export const partnersFromProductSize = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Partners/Product_Size_Available', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, error: "no data" })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const retailPartnersFromProductSize = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Partners/Product_Retail_Size_Available', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, error: "no data" })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}


export const productSimilarProducts = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Products/Related_Product_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch(err => { })
    })
}

export const recentlyVisitedProducts = (formData) => {
    return new Promise(async (resovle, reject) => {
        await nodeCudApi.post('/Recently_Visit/Create', formData).then((res) => {
        }).catch(err => { })
    })
}