import { useTheme } from '@emotion/react';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'

const style = {
    position: 'absolute',
    top: '40%',
    left: { xs: "7.5%", md: '37.5%' },
    width: { xs: "70%", md: "25%" },
    height: { xs: "auto", md: "auto" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: "20px"
};


const ReturnCancelModal = ({ Message = "", Modal_Open = false, Handle_Modal_Open = (() => { }), Title = "Your Order is Declined", yes = (() => { }), no = (() => { }), returnCancelLoading = false }) => {
    const theme = useTheme()
    return (
        <Modal
            open={Modal_Open}
            onClose={() => Handle_Modal_Open(false)}
        >
            <Box  {...style} >
                <Box sx={{ height: "30%", display: "flex", justifyContent: "center", alignItems: "center", }} >
                    <Typography variant='h5' sx={{ textAlign: "center" }} >{Title}</Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", flexDirection: "row", flexWrap: "wrap" }} >
                    {
                        returnCancelLoading
                            ?
                            <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
                            :
                        <Button color='primary' onClick={yes} >Yes</Button>
                    }
                    <Button color='secondary' onClick={no} >No</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ReturnCancelModal