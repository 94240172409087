import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CardMedia,
    Card,
    Stack,
    Pagination as PN,
    Skeleton
} from "@mui/material";
import { useTheme } from '@emotion/react';
import useScreenSize from '../../../utils/ScreenWidthHight';
import { useSelector, useDispatch } from 'react-redux';
import NotificationSlice, { fetchNotifications, readNotification } from '../../../store/slices/retail/home/NotificationSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../Context';
import notificationNotFound from '../../../assests/images/notificationNotFound.png'

const Notification = () => {
    const theme = useTheme();
    const winSize = useScreenSize();
    const { notifications = [], Pagination, loading } = useSelector(state => state.notifications);
    const [pageNo, setPageNo] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const { pathname } = useLocation();
    const { searchParams, setSearchParams, updateQueryParam } = useContext(GlobalContext);

    useEffect(() => {
        if (searchParams.get("PageNo"))
            dispatch(fetchNotifications({
                formData: { Email_Id: auth.user.Email_Id },
                Pagination: { PageNo: searchParams.get("PageNo"), PageSize: 20, SortBy: "Notification_Id", SortOrder: "DESC" }
            }));
        else {
            {
                let querys = "";
                searchParams.forEach((value, key) => {
                    querys += "&" + key + "=" + value
                });
                navigate('/accounts/Notification?PageNo=1' + querys, { replace: true });
            }
        }
    }, [searchParams, pathname]);

    const readNotificationCall = async (notification) => {
        navigationFunc(notification)
        const response = await readNotification({ Notification_Id: notification.Notification_Id })
    }


    const navigationFunc = (notification) => {
        if (["RETAIL_NEW_ORDER", "RETAIL_NEW_ORDER_ACCEPTED", "RETAIL_NEW_ORDER_DELIVERED", "RETAIL_NEW_ORDER_SHIPPED", "RETAIL_NEW_ORDER_READY_FOR_SHIPPING", "RETAIL_NEW_ORDER_RETURN", "RETAIL_NEW_ORDER_EXCHANGE","RETAIL_NEW_ORDER_DECLIEND","RETAIL_NEW_ORDER__RETURN_DECLINED"].includes(notification.Type)) {
            if (notification.Order_Id)
                navigate('/accounts/orderdetails/' + notification.Order_Id)
        }
        else if (notification.Type === "Coupon") {
            if (notification.Coupond_Id === null)
                navigate('/retail/offers/coupons')
            else
                navigate('/retail/offers/coupons' + notification.Coupond_Id)

        }
        else if (notification.Type === "Deals") {
            if (notification.Deal_Id === null)
                navigate('/retail/offers/deals')
            else
                navigate('/retail/offers/deals' + notification.Deal_Id)

        }
        else if (notification.Type === "Partner") {
            if (notification.Partner_Detail_Id === null)
                navigate('/retail/partner/')
            else
                navigate('/retail/partner/' + notification.Partner_Detail_Id)

        }
        else if (notification.Type === "Product") {
            if (notification.Product_Id === null)
                navigate('/retail/products/')
            else
                navigate('/retail/products/' + notification.Product_Id)

        }
        else if (notification.Type === "User Gift") {
            if (notification.Product_Id === null)
                navigate('/gifts/giftsent/')


        }
    }


    return (
        <Box sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            gap: winSize.screenSize.width <= 749 ? 0 : 4,
            backgroundColor: theme.palette.primary.contrastText,
        }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 1
            }}>
                <Box sx={{ marginLeft: "3%", marginTop: "2%" }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: theme.palette.text.secondary, mb: { sm: '0px', md: '0px', xs: '10px' } }}>Notifications</Typography>
                </Box>
            </Box>
            <Box sx={{
                width: "100%",
                minHeight: "50%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                alignItems: "center",
                cursor: "pointer",
                gap: 2
            }}>
                {notifications.length === 0 && !loading && (
                    <img src={notificationNotFound} alt="notificationNotFound" style={{ display: "block", width: '50%', height: '50%', margin: 'auto', objectFit: 'contain' }} ></img>
                )}
                {(!loading && notifications.length > 0) && notifications.map((notification, index) => {
                    return (
                        <Card key={index} sx={{
                            width: { md: "645px", sm: '625px', xs: '96%' },
                            minHeight: "80px",
                            borderRadius: 6,
                            border: `1px solid ${theme.palette.input.borderColor}`,
                            boxShadow: "none",
                            zIndex: 1,
                            color: notification.Is_User_Read === 1 ? "black" : theme.palette.primary.contrastText,
                            backgroundColor: notification.Is_User_Read === 1 ? "transparent" : theme.palette.input.borderColor,
                            cursor: "pointer"
                        }}

                            onClick={() => {
                                readNotificationCall(notification)
                            }}

                        >
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                <Typography variant="h6" sx={{ marginLeft: "16px", marginTop: "15px", fontWeight: 'bold' }}>
                                    {notification.Subject}
                                </Typography>
                                {notification.Is_User_Read === 1 ? (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <CardMedia
                                            image={require('../../../assests/images/read.png')}
                                            sx={{ width: 20, height: 20, marginTop: "18px" }}
                                        />
                                        <Typography variant="body2" sx={{ marginRight: "20px", marginTop: "20px" }}>
                                            READ
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="body2" sx={{ marginRight: "20px", marginTop: "20px" }}>
                                        NEW
                                    </Typography>
                                )}
                            </Box>
                            <Typography variant="body2" sx={{ width: "100%", wordWrap: 'break-word', color: notification.Is_User_Read === 1 ? theme.palette.text.secondary : theme.palette.primary.contrastText, marginLeft: "16px", fontWeight: 'bold' }}>
                                {notification.Description}
                            </Typography>
                        </Card>
                    )
                }
                )}
                {
                    loading &&

                    Array(20).fill(1)?.map((notification, index) => (
                        <Card key={index} sx={{
                            width: "625px",
                            minHeight: "80px",
                            // borderRadius: 6,
                            // border: `1px solid ${theme.palette.input.borderColor}`,
                            boxShadow: "none",
                            zIndex: 1,
                            // backgroundColor: notification.Is_User_Read === 1 ? "transparent" : theme.palette.input.borderColor,
                            cursor: "pointer"
                        }}



                        >
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Skeleton variant='rectangular' />
                                {notification.Is_User_Read === 1 ?
                                    (
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <Skeleton
                                                sx={{ width: 20, height: 20, marginTop: "18px" }}
                                            />
                                            <Skeleton variant="text" sx={{ marginRight: "20px", marginTop: "20px" }} />
                                        </Box>
                                    )
                                    : (
                                        <Skeleton variant="text" sx={{ marginRight: "20px", marginTop: "20px" }} />
                                    )
                                }
                            </Box>
                            <Skeleton variant="text" sx={{ width: "100%", wordWrap: 'break-word', color: theme.palette.text.secondary, marginLeft: "16px" }} />
                        </Card>
                    ))}

            </Box>
            {Pagination?.TotalPages > 0 ? <Box sx={{ display: 'flex', justifyContent: "center", width: "100%", mt: '6px' }} >
                <PN page={Pagination?.PageNo} onChange={(e, page) => {
                    setPageNo(page)
                    updateQueryParam([{ "PageNo": page }])
                }} count={Pagination?.TotalPages} color='primary' />
            </Box> : null}
        </Box>
    );
}
export default Notification
