import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { OrderInvoiceAction, RetailOrderInvoiceAction, invoiceGet } from '../../../../store/slices/accounts/orders/OrdersSlice';
import { saveAs } from 'file-saver';
import { isEmpty } from '../../../../utils';
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { useTheme } from '@emotion/react';

const PaymentSummary = ({ Order_Details = {}, Order_Type = "GROCERY", Order_Id, id, Partner_Selling_Price, Quantity, Igst, Vat, IgstAmount, VatAmount, Total_Tax, Gift_Wrap_Amount, Delivery_Person_Fee, Coupon_Discount, isGrocery, groceryProducts, }) => {
    const [invoiceData, setInvoiceData] = useState(false);
    const [invoiceDetails, setDetails] = useState({});
    const theme = useTheme();
    const winsize = useScreenSize()
    useEffect(() => {

        if (Boolean(Order_Id) && !isEmpty(Order_Type)) {
            orderInvoiceActionCall(Order_Id)
        }
    }, [Order_Id, Order_Type]);

    const orderInvoiceActionCall = async (Order_Id) => {
        const response = await RetailOrderInvoiceAction(Order_Id)
        if (response.status) {
            setDetails({ ...response.data, User_Billing_Address_Full: JSON.parse(response.data.User_Billing_Address_Full), User_Shipping_Address_Full: JSON.parse(response.data.User_Shipping_Address_Full) })
        }
        else {
            setDetails({})
            setInvoiceData(false)
        }
    }

    useEffect(() => {
        if ((Order_Details.Order_Status !== "NEW" && (!isEmpty(invoiceDetails?.Partneraddress)))) {
            setInvoiceData(true)
        } else {
            setInvoiceData(false)
        }
    }, [Order_Details, invoiceDetails])

    const handleDownloadInvoice = () => {
        const html = getInvoice();
        const pW = window.open('_blank');
        pW.document.write(html);
        pW.onload = () => {
            pW.print();
        };
        pW.onclick = () => {
            pW.close();
        };
        pW.document.close();
    };





    const getInvoice = () => {

        let productRow = "";

        // invoiceDetails?.product?.map((product) => {
        //     productRow += `<tr> 
        //                 <td style="text-align: center;" >1</td>
        //                 <td style="width:50%;text-align: center;" >`+ product.Name + `</td>
        //                 <td style="text-align: center;" >`+ product.Product_Price + `</td>
        //                 `+ (product.Discount ? `<td style="text-align: center;" >` + product.Discount + `</td>` : ``) + `
        //                 <td style="text-align: center;" >`+ product?.Product_Selling_Price + `</td>
        //                 <td style="text-align: center;" >`+ product.Qty + `</td>
        //                 <td style="text-align: center;" >`+ product.GrandTotal + `</td>
        //                 </tr>`
        // })

        try {
            return `
        <html>
        <head>
            <style>
            .grid-container {
            display: grid;
            grid-template-columns: auto auto;
            }.hr.solid {
            border-top: 5px solid #27b6cc;
            }
            table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            pointer-events:none;
            }
            td{
            border: 1px solid #27b6cc;
            text-align: right;
            padding: 8px;
            }
            th {
            border: 1px solid #27b6cc;
            text-align: right;
            background-color: #27b6cc;
            padding: 8px;
            }
            </style>
            
                    <meta charset="utf-8">
                    <title>Invoice</title>
                    <script src="script.js"></script>
                </head>
                <body onclick="window.print()" >
                    <header>
                <center>
                        <h1 style="color:#27b6cc;">Invoice</h1>
                </center>
                        <address contenteditable>
                <div class="grid-container">
                <img src="`+ require('../../../../assests/images/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
                <span style="text-align:right">
                <p>Order Id: `+ invoiceDetails.Order_Id + `</p>
                <p>Order Date: `+ invoiceDetails.Invoicedate + `</p>
                <p>Invoice No: `+ invoiceDetails.Invoice_Number + `</p>
                <p>Invoice Date:`+ invoiceDetails.Invoicedate + `</p>
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4>Billing Address:</h4>
                `+
                (!isEmpty(invoiceDetails?.User_Billing_Address_Full) ?
                    `<p>` + invoiceDetails?.User_Billing_Address_Full?.NAME + `</p>
                <p>`+ (invoiceDetails?.User_Billing_Address_Full.DOOR_NO + "," + invoiceDetails?.User_Billing_Address_Full.STREET) + `</p>
                <p>`+ invoiceDetails?.User_Billing_Address_Full?.CITY + `,` + invoiceDetails?.User_Billing_Address_Full?.STATE + `,` + invoiceDetails?.User_Billing_Address_Full?.COUNTRY + `</p>
                <p>${invoiceDetails?.User_Billing_Address_Full?.MOBILE}</p>
                <p>`+ invoiceDetails?.User_Billing_Address_Full?.USER_EMAIL_ID + `</p>`
                    :
                    `<p></p>`
                )
                +
                `
                </span>
                <span style="text-align:right">
                <h4>Shipping Address:</h4>
                `+
                (!isEmpty(invoiceDetails?.User_Shipping_Address_Full) ?
                    `<p>` + invoiceDetails?.User_Shipping_Address_Full?.NAME + `</p>
                <p>`+ (invoiceDetails?.User_Shipping_Address_Full.DOOR_NO + "," + invoiceDetails?.User_Shipping_Address_Full.STREET) + `</p>
                <p>`+ invoiceDetails?.User_Shipping_Address_Full?.CITY + `,` + invoiceDetails?.User_Shipping_Address_Full?.STATE + `,` + invoiceDetails?.User_Shipping_Address_Full?.COUNTRY + `</p>
                <p>${invoiceDetails?.User_Shipping_Address_Full?.MOBILE}</p>
                <p>`+ invoiceDetails?.User_Shipping_Address_Full?.USER_EMAIL_ID + `</p>`
                    :
                    `<p></p>`
                ) +
                `
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4>Sold By:</h4>
                `+ (!isEmpty(invoiceDetails?.Partneraddress) ?
                    `<p>` + invoiceDetails.Partner_Name + `</p>
                <p>`+ (invoiceDetails.Partneraddress.Door_No + invoiceDetails.Partneraddress.Land_Mark + invoiceDetails.Partneraddress.Street) + `</p>
                <p>`+ invoiceDetails.Partneraddress.City_Name + `,` + invoiceDetails.Partneraddress.State + `,` + invoiceDetails.Partneraddress.Country + `</p>
                <p>`+ invoiceDetails.Partneraddress.Name + `</p>` :
                    `<p></p>`
                ) + `
                </span>
                <span style="text-align:right">`+
                (invoiceDetails?.Pan ? `<p>PAN NO: ${invoiceDetails?.Pan}</p>` : "")
                + (invoiceDetails?.GST ? ` <p>GST: ${invoiceDetails?.GST}</p>` : "") +
                `
                </span>
                </div>
                <hr class="solid">
                <table>
                    <tr>
                    <th style="text-align: center;" >SI No</th>
                    <th style="width:50%;text-align: center;">Description</th>
                    <th style="text-align: center;" >Rate</th>
                    <th style="text-align: center;" >Discount</th>
                    <th style="text-align: center;" >Amount</th> 
                    <th style="text-align: center;" >QTY</th>
                    <th style="width:10%;text-align: center;" >Total</th>
                    </tr>
                   
                    <tr> 
                        <td style="text-align: center;" >1</td>
                        <td style="width:50%;text-align: center;" >`+ invoiceDetails.Product_Name + `</td>
                        <td style="text-align: center;" >`+ invoiceDetails.Mrp + `</td>
                        <td style="text-align: center;" >` + (invoiceDetails.Mrp - invoiceDetails.Base_Selling_Price) + `</td>
                        <td style="text-align: center;" >`+ invoiceDetails.Base_Selling_Price + `</td>
                        <td style="text-align: center;" >`+ invoiceDetails.Quantity + `</td>
                        <td style="text-align: center;" >`+ Number(invoiceDetails.Quantity_Selling_Price).toFixed(2) + `</td>
                        </tr>
                    `

                + (
                    `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;"> IGST  </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Sgst_Percentage + invoiceDetails.Cgst_Percentage) + `% </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Sgst + invoiceDetails.Cgst).toFixed(2) + ` </td>
                    </tr>`
                ) +
                (`
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;"> VAT  </td>
                    <td style="text-align: center;"> `+ invoiceDetails.Vat_Percentage + `% </td>
                    <td style="text-align: center;"> `+ invoiceDetails.Vat + ` </td>
                    </tr>`) +
                `<tr>
                
                <tr>
                `
                +
                (
                    `<td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;"> Total Tax  </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Sgst_Percentage + invoiceDetails.Cgst_Percentage + invoiceDetails.Vat_Percentage) + `% </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Total_Tax).toFixed(2) + ` </td>
                    `
                )
                +
                (
                    invoiceDetails.Gift_Wrap_Amount > 0 ?
                        `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;" colspan="2" > Gift Wrap Amount </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Gift_Wrap_Amount) + ` </td>
                    </tr>
                    
                    `: ""
                )
                + (
                    `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;" colspan="2" > Shipping Charges </td>
                    <td style="text-align: center;"> `+ Number(invoiceDetails.Shipping_Amount) + ` </td>
                    </tr>
                    
                    `)
                +

                `
                </tr>
                <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>

                    <tr>
                    <td></td>
                    <td>  </td>
                    <td></td>
                    <td style="text-align: center; font-weight: bold;" colspan="3">
                    Final Amount
                    </td>
                    <td style="text-align: center; font-weight: bold;"> `+ (Number((invoiceDetails.Quantity_Selling_Price) + Number(invoiceDetails.Shipping_Amount)).toFixed(2)) + ` </td>
                    </tr>

                    <tr>
                        <td style="text-align: left; font-weight: bold;" colspan="2">
                        Amount in words
                        </td>
                        <td style="text-align: left; font-weight: bold;" colspan="5">
${invoiceDetails.Final_Amount_In_Words}                        </td>
                    </tr>
            
                </table>
            </header>
            <aside>
                <div contenteditable>
                    <p>Note: This is computer generated Invoice and signature is not required.</p>
                </div>
            </aside>
        </body>
        </html>`;
        } catch (error) {
            // <tr>
            //     <td style="text-align: left; font-weight: bold;" colspan="7">
            //         Amount in words:${Order_Type === "GROCERY" ? invoiceDetails.Order_Totals.AmountInWords : invoiceDetails.product[0].AmountInWords}
            //     </td>
            // </tr>
            console.log(error)
            return <h1>error</h1>
        }

    }


    return (
        <Box sx={{ width: "100%" }} >
            <Box>
            </Box>
            <Grid item xs={12} >
                <TableContainer sx={{ width: "100%" }}  >
                    <Table sx={{ borderCollapse: 'collapse', '& th, & td': { borderBottom: 'none' }, width: "100%" }}>
                        <TableHead sx={{ width: "100%", alignSelf: "flex-start" }} >
                            <TableRow  >

                                <TableCell sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: 0 }}>
                                    <>
                                        <Tooltip title="Item Price" placement="top" >
                                            <Typography component="span" sx={{ color: theme.palette.text.secondary, }}>
                                                {Number(Partner_Selling_Price).toFixed(2)}
                                            </Typography>
                                        </Tooltip>
                                        <Tooltip title="Quantity" placement="top" >
                                            <Typography component="span" sx={{ color: theme.palette.text.secondary, }}>
                                                {" "} * {Quantity?.toString()}
                                            </Typography>
                                        </Tooltip>
                                    </>
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    {Boolean(isGrocery) ?
                                        groceryProducts.map((Product, index) => (
                                            <Typography key={index} component="span" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>
                                                {Product.Partner_Selling_Price?.toFixed(2)?.toString()}
                                            </Typography>
                                        )) :
                                        <>
                                            {`${(Number(Quantity) * Number(Partner_Selling_Price)).toFixed(2).toString()}`}
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                            {Boolean(isGrocery) ?
                                groceryProducts.map((Product, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: 0 }}>
                                            {`Quantity * ${Product.Quantity?.toString()}`}
                                        </TableCell>
                                        <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>
                                            {`${Number(Product.Quantity) * Number(Product.Partner_Selling_Price).toFixed(2).toString()}`}
                                        </TableCell>
                                    </TableRow>
                                )) :
                                <></>
                                // <TableRow>
                                //     <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "10px" }}>
                                //         Item Price
                                //     </TableCell>
                                //     <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>
                                //         <Typography component="span" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold', paddingLeft: "20px" }}>
                                //             {Number(Partner_Selling_Price)?.toFixed(2)?.toString()}
                                //         </Typography>
                                //     </TableCell>
                                // </TableRow>
                            }
                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "20px" }}>
                                    Total Tax <Typography component="span" sx={{ color: theme.palette.text.secondary, }}>{(Igst + Vat)?.toString()}%</Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>
                                        {Total_Tax?.toFixed(2)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "40px" }}>IGST
                                    <Typography component="span" sx={{ color: theme.palette.text.secondary, }}>  {(Igst)?.toString()}%</Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>{Number(IgstAmount)?.toFixed(2)?.toString()}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "40px" }}>
                                    VAT <Typography component="span" sx={{ color: theme.palette.text.secondary, }}>{(Vat)?.toString()}%</Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>
                                        {Number(VatAmount)?.toFixed(2)?.toString()}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "0px" }}>Coupon Discount</TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>{Number(Coupon_Discount)?.toFixed(2)?.toString()}</Typography>
                                </TableCell>
                            </TableRow>
                            {Boolean(Gift_Wrap_Amount && Gift_Wrap_Amount !== "0.00") &&
                                <TableRow>
                                    <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "0px" }}>Gift Wrap Charges</TableCell>
                                    <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                        <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>{Number(Gift_Wrap_Amount)?.toFixed(2)?.toString()}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingLeft: "0px" }}>Delivery Charges</TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>{Number(Delivery_Person_Fee)?.toFixed(2)?.toString()}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold', paddingLeft: 0 }}>Total Price</TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' } }}>
                                    <Typography sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 'bold' }}>{(((Number(Quantity) * Number(Partner_Selling_Price)) - Number(Coupon_Discount)) + Number(Delivery_Person_Fee) + Number(Gift_Wrap_Amount)).toFixed(2)?.toString()}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>

                <Box sx={{ textAlign: "right" }}>
                    {/* {invoiceData && !['RETURN', 'CANCELLED', "ACCEPTED", "NEW", "IN-TRANSIT", "SHIPPED", "OUT-FOR-DELIVERY","ORDERED"].includes(Order_Status) && ( */}
                    {invoiceData && <Button type="button" variant="contained" size="medium" onClick={handleDownloadInvoice}>
                        Download Invoice
                    </Button>
                    }
                    {/* )} */}
                </Box>
            </Grid>
        </Box >
    );
};

export default PaymentSummary;
