import React, { useMemo } from 'react';
import { Card, CardActionArea, CardMedia, Typography, Box, Tooltip, Skeleton } from '@mui/material';
import { useTheme } from "@emotion/react";
import useScreenSize from '../../../../utils/ScreenWidthHight';
import delivaryVan from '../../../../../src/assests/images/deliveryvanInTime.png'
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../../../utils';
import AutoAdjustText from '../../../../utils/AutoAdjustText';
import { fontWeight } from '@mui/system';

const OrdersCard = ({ Partner_Name = "", logoPath = "", Product_Id = "", Item_Master_Id = "", Product_Name = "", Order_Id = "", Order_Date = "", Delivered_Date = "", Expected_Delivery_Date_Time = "", Order_Status = "", type = "", isSkeleton = false }) => {
    const theme = useTheme();
    const winSize = useScreenSize();
    const navigate = useNavigate();

    const cardWidth = useMemo(() => {
        let cardWidth = (winSize.getWidthForGiveInput(3) - 30);
        if (cardWidth <= 340) {
            cardWidth = 340;
        }
        if (winSize.screenSize.width <= cardWidth) {
            cardWidth = winSize.screenSize.width - 30;
        }
        return cardWidth + "px";
    }, [winSize.screenSize.width]);

    const statusColor = (Order_Status && (Order_Status?.toLowerCase() === 'delivered' || Order_Status?.toLowerCase() === 'ordered') || Order_Status?.toLowerCase() === "settled" || Order_Status?.toLowerCase() === "accepted")
        ? theme.palette.success.main : (Order_Status && Order_Status?.toLowerCase() === 'cancelled') ? theme.palette.error.main : (Order_Status && Order_Status?.toLowerCase() === 'new') ? theme.palette.primary.main : Order_Status?.toLowerCase() === "Ready_for_shipping".toLowerCase() ? theme.palette.primary.main : 'black';
    if (!isSkeleton)
        return (
            <Card sx={{ margin: { xs: "0px", sm: '10px' }, borderRadius: '24px', marginBottom: '10px', width: { xs: "86.945%", sm: "45%" }, height: '238px', border: '1px solid', borderColor: theme.palette.primary.main }}>
                <Box display="flex" height="100%">
                    <CardActionArea style={{ width: '50%', display: "flex", justifyContent: "center" }}>
                        <Link to={`/accounts/orderdetails/${Order_Id}`} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" ,alignItems:"center"}} target='_blank' >
                            <CardMedia
                                component="img"
                                image={logoPath}
                                alt="product_Image"
                                sx={{ objectFit: "fill", height: "90%", width: "80%", borderRadius: "12px" }}

                            />
                        </Link>
                    </CardActionArea>

                    <Box width="60%" p={2} display="flex" flexDirection="column" sx={{ justifyContent: { xs: "flex-start", sm: "space-around" } }}>
                        <Box sx={{ ...(winSize.screenSize.width < 600 ? { marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "space-around", height: "80%" } : {}) }} >
                            {winSize.screenSize.width < 600 ?
                                <AutoAdjustText style={{ fontSize: "18px" }} numLines={1} >
                                    {Product_Name}
                                </AutoAdjustText>

                                : <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: "clamp(12px, 1.2vw, 16px)" }}>
                                    <strong>{Product_Name?.length > 30 ? Product_Name?.substring(0, 25) + "..." : Product_Name}</strong>
                                </Typography>}
                            {winSize.screenSize.width < 600 ?
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                    <Typography>Seller: </Typography>
                                    <AutoAdjustText style={{ fontSize: "18px", fontWeight: "bold" }} numLines={1} >
                                        &nbsp;{Partner_Name}
                                    </AutoAdjustText>
                                </Box>
                                :
                                <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: { xs: "16px", sm: "clamp(12px, 1.2vw, 16px)" }, }} >Seller: <strong>{Partner_Name?.length > 20 ? Partner_Name?.substring(0, 20) + "..." : Partner_Name}</strong></Typography>}
                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: { xs: "16px", sm: "clamp(12px, 1.2vw, 16px)" }, }}>Order Id: <strong>{Order_Id}</strong></Typography>
                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: { xs: "16px", sm: "clamp(08px, 1.2vw, 13px)" }, }}>Ordered Date: <strong>{Order_Date?.slice(0, 10)}</strong></Typography>
                            <Box display="flex" alignItems="center">
                                {/* <Tooltip title="delivered date-time">
                                    <Box component="img" src={delivaryVan} alt="Delivery van" sx={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                </Tooltip> */}
                                {(Order_Status?.toLowerCase() === 'delivered' && !isEmpty(Delivered_Date)) && <Typography
                                    variant="body1"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontSize: { xs: "16px", sm: "clamp(08px, 1.2vw, 13px)" }
                                    }}
                                >Delivered Date:

                                    <strong>  {Delivered_Date?.slice(0, 10)}</strong>
                                </Typography>}
                            </Box>

                            {/* Conditionally render expected delivery section */}
                            {(Expected_Delivery_Date_Time && !(Order_Status?.toLowerCase() === 'delivered')) && (
                                <Box display="flex" alignItems="center">
                                    {/* <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize:  "clamp(08px, 1.2vw, 13px)" }}>
                                        Expected:
                                    </Typography> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ whiteSpace: 'nowrap' }} >Expected Date: </Typography>
                                        <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: { xs: "16px", sm: "clamp(08px, 1.2vw, 13px)" }, marginLeft: '4px' }}>
                                            <strong>{Expected_Delivery_Date_Time.slice(0, 10)}</strong>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}

                            <Typography variant="body1" sx={{ textAlign: 'right' }}>
                                Status: <strong style={{ color: statusColor }}>{Order_Status[0] + Order_Status.slice(1).toLowerCase()}</strong>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        )
    else
        return (
            <Card sx={{ margin: '10px', borderRadius: '24px', marginBottom: '10px', width: cardWidth, height: '238px', border: '1px solid', borderColor: theme.palette.primary.main }}>
                <Box display="flex" height="100%">
                    <CardActionArea style={{ width: '50%' }} >
                        <Skeleton
                            height="100%"
                            sx={{ objectFit: "fill" }}
                        />
                    </CardActionArea>

                    <Box width="50%" p={2} display="flex" flexDirection="column" justifyContent="space-around">
                        <Box>
                            <Skeleton variant='text' />
                            <Skeleton variant='text' />
                            <Skeleton variant='text' />
                            <Skeleton variant='text' />
                            <Box display="flex" alignItems="center">
                                {/* <Tooltip title="delivered date-time">
                                    <Box component="img" src={delivaryVan} alt="Delivery van" sx={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                </Tooltip> */}
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontSize: "clamp(12px, 1.2vw, 16px)",
                                    }}
                                />

                            </Box>

                            {/* Conditionally render expected delivery section */}
                            {Expected_Delivery_Date_Time && (
                                <Box display="flex" alignItems="center">
                                    <Skeleton variant='text' />
                                </Box>
                            )}

                            <Skeleton variant='text' />
                        </Box>
                    </Box>
                </Box>
            </Card>
        );
};

export default OrdersCard;
