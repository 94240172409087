import * as React from "react";
import Retail from "../screens/retail";
import Rides from "../screens/rides";
import Services from "../screens/services";
import Gifts from "../screens/gifts/home/index.js";
import Auth from "../screens/auth";
import SignInOTP from "../screens/auth/SignInOTP";
import SignIn from "../screens/auth/SignIn";
import SignUp from "../screens/auth/SignUp";
import RetailHome from "../screens/retail/home";
import { Navigate } from "react-router-dom";
import ROffers from "../screens/retail/offers/Offers";
import AddAddress from "../screens/accounts/Address/AddAddress";
import Shop from "../screens/shop/Shop";
import UpdateProfile from "../screens/auth/UpdateProfile";
import RCouponRetail from "../screens/retail/offers/offerDetails/CouponDetails.js";
import RCouponDeals from "../screens/retail/offers/offerDetails/DealDetails.js";
// import RCouponRetail from "../screens/retail/offers/coupondetails/RetailDetails";
// import RCouponDeals from "../screens/retail/offers/coupondetails/DealDetails";
import RAllProducts from "../screens/retail/products";
import RBrandReview from "../screens/retail/brandPage/BrandReview";
import RPartnerReview from "../screens/retail/partnerPage/PartnerReview";
import RCategory from "../screens/retail/categories/CategoryPage";
import RSubCategory from "../screens/retail/categories/SubCategory_page";
import RAllDeals from "../screens/retail/offers/AllDeals";
import Cart from "../screens/accounts/cart/Cart";
import Retail_Cart from "../screens/accounts/retailCart/Cart.js";
import Retail_Checkout from "../screens/accounts/retailCheckout/CheckOut.js";
import Grocery_Cart from "../screens/accounts/groceryCart/Cart.js";
import Grocery_Checkout from "../screens/accounts/groceryCheckout/CheckOut.js";
import Wallet from "../screens/accounts/Wallet";
import ReferAndEarn from "../screens/accounts/Refer&Earn";
import RAllCoupons from "../screens/retail/offers/AllCoupons";
import CheckOutDetails from "../screens/accounts/checkOutPage/CheckOut";
import OrdersDetailsPage from "../screens/accounts/orders/retail/OrdersDetailsPage.js";
import DeleteAccount from "../screens/auth/DeleteAccount";
import ReviewRating from "../screens/accounts/cms/ReviewsandRating";
import NewsEvents from "../screens/accounts/cms/NewsEvents";
import Faqs from "../screens/accounts/cms/Faq";
import AboutUs from "../screens/accounts/cms/AboutUs.js";
import Termscondition from "../screens/accounts/cms/Terms&conditons";
import PrivacyPolicy from "../screens/accounts/cms/PrivacyPolicy";
import MarketingStrategy from "../screens/accounts/cms/MarketingStrategies.js";
import Copyrights from "../screens/accounts/cms/Copyrights.js";
import ShippingAndDelivery from "../screens/accounts/cms/ShippingAndDelivery.js";
import ReturnAndExchange from "../screens/accounts/cms/ReturnAndExchange.js";
import Contactus from '../screens/accounts/cms/ContactPage'
import Notification from '../screens/accounts/cms/Notifications'
import CreateTicket from '../screens/accounts/cms/createTickets/Create_Ticket.js'
import ROrders from "../screens/accounts/orders/retail/Orders.js";
import Products from "../screens/retail/products";
import ProductDetail from "../screens/retail/ProductDetail.js/index.js";
import Wishlist from "../screens/retail/wishlist/Wishlist";
import GWishlist from "../screens/grocery/home/GroceryWishlist"
import Tickets from "../screens/accounts/cms/createTickets/Tickets.js";
import BrandPage from "../screens/retail/home/BrandPage.js";

//Add Grocery related path below and Add "G" in fornt of name
import Grocery from "../screens/grocery";
import Groceryhome from "../screens/grocery/home";
import GAllDeals from "../screens/grocery/offers/AllDeals";
import GAllCoupons from "../screens/grocery/offers/AllCoupons";
import GCategoryPage from "../screens/grocery/categories/CategoryPage";
import GSubCategory from "../screens/grocery/categories/SubCategory";
import GPartnerReview from "../screens/grocery/partnerPage/PartnerReview.js";
import GBrandReview from "../screens/grocery/brandPage/BrandReview.js";
import GDealsDetails from "../screens/grocery/offers/offerDetails/DealDetails.js";
import GRetailDetails from "../screens/grocery/offers/offerDetails/CouponDetails.js";
// import GDealsDetails from "../screens/grocery/home/offers/coupondetails/DealDetails";
// import GRetailDetails from "../screens/grocery/home/offers/coupondetails/RetailDetails";
import GShop from "../screens/grocery/shop/Shop";
import GOffers from "../screens/grocery/offers/Offers";
import BrandPageGrocery from '../screens/grocery/home/BrandPageGrocery.js'
import GAllProducts from "../screens/grocery/products/index.js";
import GProductDetail from "../screens/grocery/ProductDetail.js/index.js";

//gifts
import GiftSend from "../screens/gifts/home/GiftSend.js";
import SentOrReceived from "../screens/gifts/home/SentOrReceived.js";
import Giftvendors from "../screens/gifts/home/GiftVendors.js";
import Giftpage from "../screens/gifts/home/Gift_Page.js";
import Headergifts from "../screens/gifts/index.js";
import ChangePassword from "../screens/auth/Changepassword.js";
import GOrders from "../screens/accounts/orders/grocery/Orders.js";
import GOrdersDetails from "../screens/accounts/orders/grocery/OrdersDetailsPage.js";
import PaymentSuccess from "../screens/accounts/checkOutPage/PaymentSuccess.js";
import RidesHome from "../screens/rides/home/RidesHome.js";
import ContextIndex from "../Context.js";
import ForgotPassword from "../screens/auth/ForgotPassword.js";
import ReturnPolicy from "../screens/accounts/cms/ReturnPolicy.js";
import NewCart from "../screens/accounts/NewCart/index.js";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import PaymentFailed from "../screens/accounts/retailCheckout/PaymentFailed.js";

const returnRoutes = (isAuthenticated = false) => {
    const routes = [
        {
            path: '',
            element: <ContextIndex />,
            children: [
                {
                    path: '/retail',
                    element: <Retail />,
                    children: [
                        { path: 'home', element: <RetailHome /> },
                        { path: '', element: <Navigate to="home" /> },
                        { path: 'products', element: < RAllProducts /> },
                        { path: 'offers/Coupons/:id', element: < RCouponRetail /> },
                        { path: 'offers/Deals/:id', element: < RCouponDeals /> },
                        { path: 'brand/:id', element: <RBrandReview /> },
                        { path: 'partner/:id', element: <RPartnerReview /> },
                        { path: 'category', element: < RCategory /> },
                        { path: 'SubCategory/:id', element: < RSubCategory /> },
                        { path: 'products', element: < Products /> },
                        { path: 'brandpage', element: < BrandPage /> },
                        { path: 'products/:id', element: < ProductDetail /> },


                        {
                            path: "offers",
                            element: <ROffers />,
                            children: [
                                { path: "deals", element: <RAllDeals /> },
                                { path: "coupons", element: <RAllCoupons /> },


                            ]
                        },


                    ]
                },
                {

                    path: '/retail',
                    element: isAuthenticated ? <Retail /> : <Navigate to="/auth" />,
                    children: [
                        { path: 'cart', element: <Retail_Cart /> },
                        { path: 'checkout/:partnerId', element: <Retail_Checkout /> },
                    ]
                },

                // Grocery Cart & Checkout

                // {

                //     path: '/grocery',
                //     element: isAuthenticated ? <Grocery /> : <Navigate to="/auth" />,
                //     children: [
                //         { path: 'cart', element: <Grocery_Cart /> },
                //         { path: 'checkout/:partnerId', element: <Grocery_Checkout /> },
                //     ]
                // },
                {
                    path: "/retail/shop", element: <Retail />, children: [
                        { path: "", element: <Shop /> }
                    ]
                },

                // Grocery

                // {
                //     path: "/grocery",
                //     element: <Grocery />,
                //     children: [
                //         { path: 'home', element: <Groceryhome /> },
                //         { path: '', element: <Navigate to="home" /> },
                //         { path: 'products', element: < GAllProducts /> },
                //         { path: 'brandpage', element: < BrandPageGrocery /> },
                //         { path: 'offers/Coupons/:id', element: < GRetailDetails /> },
                //         { path: 'offers/Deals/:id', element: < GDealsDetails /> },
                //         { path: 'brand/:id', element: <GBrandReview /> },
                //         { path: 'partner/:id', element: <GPartnerReview /> },
                //         { path: 'category', element: < GCategoryPage /> },
                //         { path: 'SubCategory/:id', element: < GSubCategory /> },
                //         { path: 'wishlist', element: <GWishlist /> },
                //         { path: "shop", element: <GShop /> },
                //         { path: 'products/:id', element: < GProductDetail /> },
                //         {
                //             path: "offers",
                //             element: <GOffers />,
                //             children: [
                //                 { path: "deals", element: <GAllDeals /> },
                //                 { path: "coupons", element: <GAllCoupons /> },


                //             ]
                //         },

                //     ]

                // },

                // Rides

                // {
                //     path: "/rides",
                //     element: <Rides />,
                //     children: [
                //         { path: 'home', element: <RidesHome /> },
                //     ]
                // },

                // Services

                // { path: "/services", element: isAuthenticated ? <Services /> : <Navigate to="/auth" /> },


                {
                    path: "/gifts",
                    element: isAuthenticated ? <Headergifts /> : <Navigate to="/auth" />,
                    children: [
                        { path: 'home', element: <Gifts /> },
                        { path: 'gift', element: < Giftpage /> },
                        { path: 'giftvendor/:id', element: < Giftvendors /> },
                        { path: 'giftvendor', element: < Giftvendors /> },
                        { path: "create", element: <GiftSend /> },
                        { path: "", element: <SentOrReceived /> },
                    ]

                },

                {
                    path: "/auth",
                    element: isAuthenticated ? <Navigate to="/retail" /> : <Auth />,
                    children: [
                        { path: "signup", element: <SignUp /> },
                        { path: "signinotp", element: <SignInOTP /> },
                        { path: "signin", element: <SignIn /> },
                        { path: "forgotpassword", element: <ForgotPassword /> },
                        { path: '', element: <Navigate to="/auth/signin" /> }

                    ]
                },

                {

                    path: '/accounts',
                    element: isAuthenticated ? <Retail /> : <Navigate to="/auth" />,
                    children: [
                        { path: 'wallet', element: <Wallet /> },
                        { path: "refer&earn", element: <ReferAndEarn /> },
                        { path: "profile", element: <UpdateProfile /> },
                        { path: "address", element: <AddAddress /> },
                        { path: "address/:id", element: <AddAddress /> },
                        { path: 'cart', element: <NewCart /> },
                        { path: "checkoutdetails/:partnerId", element: <CheckOutDetails /> },
                        { path: "orderdetails/:id", element: <OrdersDetailsPage /> },
                        { path: "orders", element: <ROrders /> },
                        { path: 'createticket', element: < CreateTicket /> },
                        { path: 'notification', element: < Notification /> },
                        { path: 'deleteaccount', element: < DeleteAccount /> },
                        { path: "tickets", element: <Tickets /> },
                        { path: "changepassword", element: <ChangePassword /> },
                        { path: "paymentsuccess", element: <PaymentSuccess /> },
                        { path: "paymentfailed", element: <PaymentFailed /> },
                        { path: "wishlist", element: <Wishlist /> },

                    ]

                },
                {

                    path: '/accounts',
                    element: <Retail />,
                    children: [
                        { path: 'contactus', element: < Contactus /> },
                        { path: 'privacypolicy', element: < PrivacyPolicy /> },
                        { path: 'terms&conditions', element: < Termscondition /> },
                        { path: 'faqs', element: < Faqs /> },
                        { path: 'aboutus', element: < AboutUs /> },
                        { path: 'shippinganddelivery', element: < ShippingAndDelivery /> },
                        { path: 'returnandexchange', element: < ReturnAndExchange /> },
                        { path: 'newsandevents', element: < NewsEvents /> },
                        { path: 'Copyrights', element: < Copyrights /> },
                        { path: 'reviewsandrating', element: < ReviewRating /> },
                        { path: 'marketingstrategy', element: <MarketingStrategy /> },
                        { path: 'returnpolicy', element: <ReturnPolicy /> },
                    ]

                },

                // Grocery Accounts

                // {
                //     path: '/accounts',
                //     element: isAuthenticated ? <Grocery /> : <Navigate to="/auth" />,
                //     children: [
                //         { path: 'grocery/orders', element: <GOrders /> },
                //         { path: 'grocery/orderdetails/:id', element: <GOrdersDetails /> },
                //         { path: 'grocery/wishlist', element: <GWishlist /> },
                //     ]
                // },
                { path: '', element: <Navigate to="/retail/home" /> },
                {
                    path: "*",
                    element: (<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <Typography>Release early, release often.</Typography>
                    </Box>),
                }
            ]
        }


    ];
    return routes
}



export default returnRoutes;
