import { Autocomplete, Box, Button, CardMedia, FormControlLabel, FormHelperText, Grid, Radio, TextField, Typography } from "@mui/material";
import { isEmpty } from "../../../utils";
import { useEffect, useState } from "react";
import { getMeepaisaCashBackCoupon } from "../../../store/slices/accounts/cart/CartSlice";
import StyledPopperComponent from "../../components/StyledPopper";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddress } from "../../../store/slices/accounts/Address/Address";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";
import info from '../../../assests/images/info.png'


const CheckOut = ({
    partnerCartState,
    setPartnerCartState,
    couponCheck,
    corpVoucherCheckLocal,
    corpVoucher,
    setCorpVoucher,
    giftVoucher,
    setGiftVoucher,
    giftVoucherCheckLocal,
    addressType,
    location,
    mobileNumber,
    cashBackCoupon,
    setCashBackCoupon,
    billingAddress,
    setBillingAddress, addressError, totalMrp, allCouponDiscount,
    couponDiscountAmount }) => {

    const [meepaisaCoupons, setMeepaisaCoupons] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const authData = useSelector(state => state.auth.user)
    const address = useSelector(state => state.addressProfiles.addressProfile);
    const [showNoCouponsError, setShowNoCouponsError] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const winsize = useScreenSize()

    useEffect(() => {
        dispatch(fetchAddress(
            {
                formData: {
                    "User_Email_Id": authData?.Email_Id
                },
                pagination: { PageNo: 1, PageSize: 50 }
            }
        )
        );
        getMeepaisaCoupons()
    }, [])

    const getMeepaisaCoupons = async () => {
        let res = await getMeepaisaCashBackCoupon(authData?.Email_Id);
        if (res.status) {
            setMeepaisaCoupons(res.data);
        } else {
            setMeepaisaCoupons([])
        }
    }

    const returnOptionLabel = (data) => {
        if (data.Cashback_Label == "FLAT") {
            if (data.Cashback_Type = "A") {
                return { Name: data.Name, label: `You will get flat ₹${data.Cashback} cashback.`}
            } else {
                return { Name: data.Name, label:  `You will get up to ${data.Cashback}% cashback.` }
            }

        } else {
            if (data.Cashback_Type = "A") {
                return { Name: data.Name, label: `You will get flat ₹${data.Cashback} cashback.` }
            } else {
                return { Name: data.Name, label: `You will get up to ${data.Cashback}% cashback.` }
            }
        }
    }

    return (
        <Box sx={{ margin: 3 }}>
            <Box sx={{display:'flex', flexDirection:'row' , justifyContent:'space-between'}}>
                
                <Typography sx={{ fontSize: {md:'24px',xs:'16px',sm:'24px'}, color: theme.palette.text.secondary }}>Check Out</Typography>
                {winsize.screenSize.width < 900 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
                    <Typography sx={{ marginRight: '7px', fontSize:{md:'16px', sm:'16px', xs:'14px'} }}>Don't reload this page</Typography>
                    <img src={info} alt="info" style={{ width: '24px' , height: '24px', marginRight: '10px', }}></img>
                </Box>}
            </Box>
            <Box sx={{ border: "2px solid " + theme.palette.primary.main, borderRadius: "18px", padding: "10px", marginTop: "10px", maxWidth: "664px" }}>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px',
                    position: "relative",
                }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Box sx={{ flexDirection: "column" }}>
                        <Typography variant="subtitle1">Billing Address</Typography>
                        <Typography mt={"5px"} fontWeight={"bold"} variant="subtitle2">{billingAddress?.location}</Typography>
                    </Box>
                    <Button variant="contained" sx={{ width: "115px" }}>Change</Button>
                    <StyledPopperComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl} value={billingAddress} setValue={setBillingAddress} options={address.map((address) => {
                        return {
                            ...address, displayLabel: address.type + "," + address.DoorNo + "," + address.street + "," + address.city + "   " + address.location
                        }
                    })} placeholder={"Please select billing address."} getOptionLabel={"displayLabel"} getTitle={(addres) => {
                        
                        return addres.type + "," + addres.DoorNo + "," + addres.street + "," + addres.city
                    }} getSubTitle={(addres) => {
                        return addres.location
                    }} />
                </Box>
                <Box>
                    <Typography>{addressType}</Typography>
                    <Typography variant="body2">{location}</Typography>
                    <Typography variant="body2">{mobileNumber}</Typography>
                </Box>
            </Box>
            {!billingAddress?.id && addressError &&
                <Box sx={{ paddingLeft: "15px", }}>
                    <Typography color={"error"} >{addressError}</Typography>
                </Box>
            }
            <Box sx={{ marginTop: {md:"100px",xs:'20px'} }}>
                <Grid container spacing={2}>
                    {partnerCartState.map((item, index) => <Grid item xs={12} md={12} key={index} >

                        <Box sx={{ display: 'flex', flexDirection: "row", maxWidth: "660px" }}>
                            <TextField
                                name={item.coupon.partner.id}
                                label={item.coupon.partner.Name}
                                placeholder={"Coupon Code"}
                                fullWidth
                                variant="outlined"
                                value={item.coupon.text}
                                color={(allCouponDiscount > 0) ? "success" : (Number(allCouponDiscount) === 0.00 && item.coupon.errorMessage.includes("apply")) || (Number(allCouponDiscount) === 0.00 && item.coupon.errorMessage.includes("SUCCESFULLY")) ? "error" : "primary"}
                                onChange={(e) => {
                                    item.coupon.text = e.target.value;
                                    item.coupon.errorMessage = '';
                                    item.coupon.isLoading = false;
                                    item.coupon.result = {};
                                    let dummyCoupons = JSON.parse(JSON.stringify(partnerCartState));
                                    dummyCoupons[index] = item;
                                    setPartnerCartState(dummyCoupons);
                                }}
                            />
                            <Button type="button" disabled={item.coupon.isLoading ? true : false} onClick={() => {
                                if (!(item.coupon.result.Name >= 0))
                                    couponCheck(index)
                            }
                            } variant="contained" color={item.coupon.result.Name ? "primary" : 'primary'} sx={{ ml: 1, height: "56px", width: "131px", borderRadius: "8px" }}>{item.coupon.result.Name ? <Box sx={{ display: "flex", flexDirection: "row", gap: "3px", alignItems: "center" }} > <CardMedia image={require('../../../assests/images/checkOutApplied.png')} sx={{ width: "20px", height: "20px", objectFit: "contain" }} /> Applied</Box> : "Apply"}</Button>
                        </Box>
                        <FormHelperText sx={{ color: (item.coupon.errorMessage.includes('SUCCESFULLY') && allCouponDiscount > 0) ? "green" : "red" }} > {item.coupon.result.Name ? Number(couponDiscountAmount({ "coupon": item.coupon.result, "products": item.Products })) <= 0 ? "No discount applied; please add products eligible for coupon redemption." : item.coupon.errorMessage : item.coupon.errorMessage} </FormHelperText>
                    </Grid>)}
                    {/* <Grid item xs={12} md={12}>

                        <Box sx={{ display: 'flex', flexDirection: "row", maxWidth: "660px" }}>
                            <TextField
                                disabled={corpVoucher.loading}
                                name="corporateCode"
                                label="Corporate Voucher"
                                value={corpVoucher?.text}
                                placeholder={'Corporate Voucher'}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setCorpVoucher({ text: e.target.value, errorMessage: '', validate: {}, loading: false, result: {} });
                                }}
                                color={Boolean(corpVoucher.errorMessage) ? !isEmpty(corpVoucher.result) ? "error" : "success" : "primary"}

                            />


                            <Button type="button" disabled={corpVoucher.loading} onClick={() => {
                                if (!(corpVoucher.result?.Discount_Amount >= 0))
                                    corpVoucherCheckLocal()
                            }} variant="contained" color={corpVoucher.result?.Discount_Amount >= 0 ? "primary" : "primary"} sx={{ ml: 1, height: "56px", width: "131px", }}>
                                {corpVoucher.result?.Discount_Amount >= 0 ? <Box sx={{ display: "flex", flexDirection: "row", gap: "3px", alignItems: "center" }} > <CardMedia image={require('../../../assests/images/checkOutApplied.png')} sx={{ width: "20px", height: "20px", objectFit: "contain" }} /> Applied</Box> : "Apply"}</Button>
                        </Box>
                        {corpVoucher.result?.Discount_Amount >= 0 && <Typography color={"green"} >Corporate Voucher Applied successfully</Typography>}
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                            {corpVoucher.errorMessage || (corpVoucher.result?.Discount_Amount === 0 && "No balance left to use.") ? (
                                <FormHelperText error>
                                    {corpVoucher.errorMessage || "Corp Coupon applied successfully."}
                                </FormHelperText>
                            ) : null}
                        </Box>

                    </Grid> */}
                    <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: "row", maxWidth: "660px" }}>
                            <TextField
                                disabled={giftVoucher.loading}
                                name="giftcode"
                                label="Gift Card"
                                value={giftVoucher?.text}
                                placeholder={'Gift Card'}
                                inputProps={{ maxLength: 11 }}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setGiftVoucher({ text: e.target.value, errorMessage: '', validate: {}, loading: false, result: {} });
                                }}
                                color={Boolean(giftVoucher.errorMessage) ? !isEmpty(giftVoucher.result) ? "error" : "success" : "primary"}
                            />
                            <Button type="button" disabled={giftVoucher.loading} onClick={() => {
                                if (!(giftVoucher.result?.Gift_Remaining_Balance >= 0))
                                    giftVoucherCheckLocal()
                            }} variant="contained" color={giftVoucher.result?.Gift_Remaining_Balance >= 0 ? "primary" : "primary"} sx={{ ml: 1, height: "56px", width: "131px", }}>{giftVoucher.result?.Gift_Remaining_Balance >= 0 ? <Box sx={{ display: "flex", flexDirection: "row", gap: "3px", alignItems: "center" }} > <CardMedia image={require('../../../assests/images/checkOutApplied.png')} sx={{ width: "20px", height: "20px", objectFit: "contain" }} /> Applied</Box> : "Apply"}</Button>
                        </Box>
                        {giftVoucher.result?.Gift_Remaining_Balance >= 0 && <Typography color={"green"} >Gift Card Applied successfully</Typography>}
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                            {giftVoucher.errorMessage || (giftVoucher.result?.Gift_Remaining_Balance === 0 && "No balance left to use.") ? (
                                <FormHelperText color={"error"} >
                                    {giftVoucher.errorMessage || (giftVoucher.result?.Gift_Remaining_Balance === 0 ? "No balance left to use." : "Gift applied successfully.")}
                                </FormHelperText>
                            ) : null}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} >

                        <Box sx={{ display: 'flex', flexDirection: "row", maxWidth: "660px" }}>

                            <Autocomplete
                                name={"cashback_coupon"}
                                label={"Cashback Coupon"}
                                placeholder={"Cashback Coupon"}
                                freeSolo
                                fullWidth

                                options={meepaisaCoupons}
                                getOptionLabel={(option) => option.Name || ""}
                                renderInput={(params) => (
                                    <>
                                        <TextField {...params}
                                            label={"Cashback Coupon"}
                                            placeholder={"Cashback Coupon"}
                                            onClick={() => setShowNoCouponsError(meepaisaCoupons.length === 0)}
                                        />
                                        {showNoCouponsError && meepaisaCoupons.length === 0 && (
                                            <Typography color="error">No coupons available</Typography>
                                        )}
                                    </>
                                )}
                                renderOption={(props, option) => (
                                    <Box sx={{ padding: "5px", display: "flex", flexDirection: "column", alignContent: "flex-start", textAlign: "left" }} {...props} >
                                        <Typography variant="h6" >{returnOptionLabel(option).Name}</Typography>
                                        <Box>
                                            <Typography>{returnOptionLabel(option).label}</Typography>
                                        </Box>
                                    </Box>
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return option.Code === value.Code
                                }}
                                onChange={(event, newValue) => {
                                    setCashBackCoupon(newValue)

                                }}
                                onInputChange={(e, v) => {
                                    if (!isEmpty(cashBackCoupon)) {
                                        setCashBackCoupon({})
                                    }
                                }}
                                value={cashBackCoupon}
                            />
                            <Button type="button" variant="contained" sx={{ ml: 1, height: "56px", width: "131px" }}>
                                {cashBackCoupon?.Cashback >= 0 && Number(totalMrp) > cashBackCoupon?.Min_Order_Value ? <Box sx={{ display: "flex", flexDirection: "row", gap: "3px", alignItems: "center" }} > <CardMedia image={require('../../../assests/images/checkOutApplied.png')} sx={{ width: "20px", height: "20px", objectFit: "contain" }} /> Applied</Box> : "Apply"}</Button>
                        </Box>
                        {cashBackCoupon?.Cashback >= 0 && Number(totalMrp) > cashBackCoupon?.Min_Order_Value && <Typography color={"green"} >{"Cashback Coupon Applied"}</Typography>}
                        {Number(totalMrp) <= cashBackCoupon?.Min_Order_Value && <Typography sx={{ color: theme.palette.extra.errorMessage }} >Min Order On {cashBackCoupon?.Min_Order_Value}</Typography>}

                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CheckOut;
