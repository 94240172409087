import { CardActionArea, CardMedia, Skeleton, Tab, Tabs, Typography } from '@mui/material'
import { Box, width } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import useScreenSize from '../../../utils/ScreenWidthHight'
import { useTheme } from '@emotion/react'
import parse from 'html-react-parser';
import { catergoryNameGetFromProductID, colorVariantByProductId, createProductReviewByProductId, imagesGetByProductId, partnersFromProductSize, productDetailByProductId, productOffersGet, productReviewByProductId, recentlyVisitedProducts, sizeGetByProductId, updateImageProductReviewByProductId, updateProductReviewByProductId } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from '../../../Context'
import { useSelector } from 'react-redux'
import { ProductReviewDetails } from './ProductDetails'
import SizeChartComponent from './SizeChart'
import AutoAdjustText from '../../../utils/AutoAdjustText'
import { isEmpty } from '../../../utils';

const ProductDetailsNew = ({ Email, id, Warranty_Details, value, setValue }) => {
    const auth = useSelector(state => state.auth)
    const [productDetails, setProductDetails] = useState([])
    const [productReviewDetails, setProductReviewDetails] = useState([])
    const [reviewLoading, setReviewLoading] = useState(true)
    const [productYourReview, setProductYourReview] = useState([])
    const [categoryName, setCategoryName] = useState('')
    // const [value, setValue] = useState(0);
    const [reviewError, setReviewError] = useState('')
    const [reviewState, setReviewState] = useState(auth?.isAuthenticated === null ? false : true)
    const [editStatus, setEditStatus] = useState(false)
    const [reviewTotalPages, setReviewTotalPages] = useState(1)
    const [reviewCurrentPage, setReviewCurrentPage] = useState(1)
    const { toolTipActive } = useContext(GlobalContext);
    const fileHandleRef = useRef()
    const pathName = useLocation().pathname


    useEffect(() => {
        setEditStatus(productYourReview.length > 0 ? true : false)
    }, [productYourReview])

    useEffect(() => {
        if (!isEmpty(id)) {
            setProductReviewDetails([])
            setProductYourReview([])
            refresh(id)
        }
    }, [id, auth])

    useEffect(() => {
        if (!isEmpty(id))
            productReviewByProductIdCall({ Product_Id: id }, { PageNo: reviewCurrentPage, PageSize: 20 })

    }, [reviewCurrentPage])



    const refresh = async (id) => {
        if (value > 6) {
            setValue(0)
        }
        if (!isEmpty(id)) {
            productDetailByProductIdCall({ Product_Id: [id] })
            productReviewByProductIdCall({ Product_Id: id }, { PageNo: 1, PageSize: 20 })
            productYourReviewByProductIdCall({ Product_Id: id, Email_Id: auth.user.Email_Id },)
            catergoryNameGetFromProductIDCall({ Product_Id: id })
        }
    }

    const productDetailByProductIdCall = async (formData) => {
        const response = await productDetailByProductId(formData)
        if (response.status) {
            setProductDetails(response.data.map((ProductDetail, index) => ({
                Brand_Id: ProductDetail.Brand_Id,
                Category_Id: ProductDetail.Category_Id,
                Brand_Name: ProductDetail.Brand_Name,
                Product_Name: ProductDetail.Product_Name,
                Product_Short_Description: ProductDetail.Product_Short_Description,
                Specifications: ProductDetail.Specifications,
                Partner_Name: ProductDetail.Partner_Name,
                CashBack_Product_Price: ProductDetail.CashBack_Product_Price,
                Features: ProductDetail.Features,
                Product_Description: ProductDetail.Product_Description,
                Partner_Price: ProductDetail.Partner_Price,
                Original_Price: ProductDetail.Original_Price,
                Partner_Price: ProductDetail.Partner_Price,
                Cashback_Label: ProductDetail.Cashback_Label,
                Discount_Label: ProductDetail.Discount_Label,
                Discount: ProductDetail.Discount,
                Product_Id: ProductDetail.Product_Id,
                Partner_Product_Id: ProductDetail.Partner_Product_Id,
                Esi_Rating: ProductDetail.Esi_Rating,

            })))
            if (auth?.isAuthenticated) {
                await recentlyVisitedProducts({ User_Email_Id: auth?.user?.Email_Id, Offer_Type: "Product", Offer_Id: response.data[0].Product_Id, Store_Type: response.data[0].Partner_Type, Store_Id: response.data[0].Partner_Details_Id })
            }
        }
        else {
            setProductDetails([])
        }
    }
    const productReviewByProductIdCall = async (formData, Pagination) => {
        const response = await productReviewByProductId(formData, Pagination)
        if (response.status) {
            setProductReviewDetails(state => {
                if (state[0]?.Product_Id === response.data[0].Product_Id) {
                    return ([...state, ...response.data])
                }
                else {
                    return ([...response.data])
                }
            }
            )
            setReviewTotalPages(response.Pagination.TotalPages)
            setReviewLoading(false)
        }
        else {
            setProductReviewDetails([])
            setReviewLoading(false)
        }
    }
    const productYourReviewByProductIdCall = async (formData, Pagination) => {
        const response = await productReviewByProductId(formData, Pagination)
        if (response.status) {
            const filteredData = response.data
            setEditStatus(filteredData.length > 0 ? true : false)
            setProductYourReview(filteredData.map(ProductYourReviw => ({
                Comments: ProductYourReviw.Comments,
                Full_Name: ProductYourReviw.Full_Name,
                Profile_Pic_Path: ProductYourReviw.Profile_Pic_Path,
                Rating: ProductYourReviw.Rating,
                Review_Image: ProductYourReviw.Review_Image,
                Product_Id: ProductYourReviw.Product_Id,
                Email_Id: ProductYourReviw.Email_Id,
                Product_Review_Id: ProductYourReviw.Product_Review_Id
            })));



        }
        else {
            setProductYourReview([])
            setEditStatus(false)
        }
    }
    const catergoryNameGetFromProductIDCall = async (formData) => {
        const response = await catergoryNameGetFromProductID(formData)
        if (response.status) {
            setCategoryName(response.data)
        }
    }

    const addReview = async (payload, callback = (() => { })) => {
        const response = await createProductReviewByProductId(payload)
        if (response.status) {
            toolTipActive("success", 'Review Added');
            productYourReviewByProductIdCall({ Product_Id: id, Email_Id: auth.user.Email_Id }, { PageNo: 1, PageSize: 1 })
        }
    }
    const updateReview = async (payload) => {
        const response = await updateProductReviewByProductId(payload)
        if (response.status) {

            toolTipActive("success", 'Review Updated');
            productYourReviewByProductIdCall({ Product_Id: id, Email_Id: auth.user.Email_Id }, { PageNo: 1, PageSize: 1 })
        }

    }
    const updateImageReview = async (payload) => {
        const response = await updateImageProductReviewByProductId(payload)
        if (response.status) {
            productYourReviewByProductIdCall({ Product_Id: id, Email_Id: auth.user.Email_Id }, { PageNo: 1, PageSize: 1 })
        }
    }

    return (
        <Box sx={{ width: "100%", height: { md: "50vh", sm: "50vh", xs: "60vh" }, display: "flex", flexDirection: "column", rowGap: "2vh", justifyContent: "center" }} >
            <ButtonGroup setValue={setValue} value={value} categoryName={categoryName} />
            <Box sx={{ width: "100%", display: "flex", height: "90%", overflow: "scroll", }} >
                {value === 0 && <ProductOffers productDetails={productDetails} />}
                {value === (pathName.includes("retail") ? 1 : 99) && <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }} ><Typography>DELIVERY IN 7 Days</Typography></Box>}
                {value === (pathName.includes("retail") ? 2 : 1) && <ProductDescriptionDetails productDetails={productDetails} />}
                {value === (pathName.includes("retail") ? 3 : 2) && <SpecificationsDetails productDetails={productDetails} />}
                {value === (pathName.includes("retail") ? 4 : 3) && <FeaturesDetails productDetails={productDetails} />}
                {value === (pathName.includes("retail") ? 5 : 4) &&
                    <ProductReviewDetails
                        productYourReview={productYourReview}
                        Email={Email}
                        productDetails={productDetails}
                        editStatus={editStatus}
                        updateImageReview={updateImageReview}
                        addReview={addReview}
                        updateReview={updateReview}
                        setReviewError={setReviewError}
                        reviewState={reviewState}
                        setReviewState={setReviewState}
                        fileHandleRef={fileHandleRef}
                        reviewError={reviewError}
                        productReviewDetails={productReviewDetails.filter((item) => item.Email_Id !== auth.user.Email_Id)}
                        reviewCurrentPage={reviewCurrentPage}
                        reviewTotalPages={reviewTotalPages}
                        setReviewCurrentPage={setReviewCurrentPage}
                        Product_Id={id}
                        reviewLoading={reviewLoading}
                    />
                }
                {value === (pathName.includes("retail") ? 6 : 5) && <WarrantyDetails warrantyDetails={Warranty_Details} />}
                {value === (pathName.includes("retail") ? 7 : 6) && <SizeChartComponent Category_Name={categoryName} />}
            </Box>
        </Box>

    )
}

export default ProductDetailsNew

const ButtonGroup = ({ setValue = (() => { }), value = 0, categoryName = "" }) => {
    const screenSizes = useScreenSize()
    const pathName = useLocation().pathname
    const theme = useTheme()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabs = pathName.includes("retail") ? ['OFFERS', 'DELIVERY', 'Description', 'SPECIFICATIONS', 'Features', 'REVIEWS', 'WARRANTY', "SIZE CHARTS"] : ['OFFERS', 'Description', 'SPECIFICATIONS', 'Features', 'REVIEWS', 'WARRANTY', "SIZE CHARTS"]
    const newTabs = pathName.includes("retail") ? ['OFFERS', 'DELIVERY', 'Description', 'SPECIFICATIONS', 'Features', 'REVIEWS', 'WARRANTY'] : ['OFFERS', 'Description', 'SPECIFICATIONS', 'Features', 'REVIEWS', 'WARRANTY']
    return (
        <Box
            sx={{
                minWidth: { md: "100%", xs: "100%" },

            }}


        >
            <Tabs
                orientation={"horizontal"}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ sx: { backgroundColor: { md: "transparent" } } }}
                sx={{ transform: { md: "translateX(0px)" } }}
            >
                {
                    ["Men's Fashion", "Women's Fashion", "Kids's Fashion"].includes(categoryName) ?
                        tabs.map((tab, index) => (
                            <Tab label={tab.toUpperCase()} key={index} sx={{
                                alignSelf: "start", backgroundColor: index === value ? theme.palette.input.borderColor : "#CFD8DC",
                                borderBottom: (index !== value) && `.25px solid ${theme.palette.text.disabled}`,
                                borderRight: (index !== value) && `.25px solid ${theme.palette.text.disabled}`,
                                // width: { xs: "auto", sm: "auto", },
                                minWidth: { md: screenSizes.screenSize.width > 1600 ? "200px" : "160px" }

                            }} style={{ color: value === index ? "#fff" : "#000", fontSize: "10.5px", fontWeight: "500" }} />))
                        :
                        newTabs.map((newTab, index) => (
                            <Tab label={newTab.toUpperCase()} key={index} sx={{
                                alignSelf: "start", backgroundColor: index === value ? theme.palette.input.borderColor : "#CFD8DC",
                                borderBottom: (index !== value) && `.25px solid ${theme.palette.text.disabled}`,
                                borderRight: (index !== value) && `.25px solid ${theme.palette.text.disabled}`,
                                // width: { xs: "auto", sm: "auto", },
                                minWidth: { md: screenSizes.screenSize.width > 1600 ? "230px" : "180px" }

                            }} style={{ color: value === index ? "#fff" : "#000", fontSize: "11px", fontWeight: "500" }} />
                        ))
                }
            </Tabs>
        </Box>
    );
};

const ProductOffers = ({ productDetails = {} }) => {
    const [offersData, setOffersData] = useState([])
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const { toolTipActive } = useContext(GlobalContext)
    const productOffersGetCall = async (formData) => {
        setLoading(true)
        const response = await productOffersGet(formData)
        if (response.status) {
            setOffersData(response.data)
        }
        else {
            setOffersData([])
        }
        setLoading(false)
    }
    useEffect(() => {
        if (productDetails[0]?.Brand_Id !== undefined && productDetails[0]?.Category_Id !== undefined)
            productOffersGetCall({ Brand_Id: productDetails[0]?.Brand_Id, Category_Id: productDetails[0]?.Category_Id })
    }, [productDetails])


    return (
        <Box sx={{
            width: "100%", display: "flex", flexDirection: "column", gap: "5px"

        }} >
            {offersData.length > 0 && <Typography >Available Offers</Typography>}
            <Box sx={{ width: { xs: "100%", sm: "90%" }, }} >
                {offersData.length !== 0 && <Box sx={{ width: "100%", maxHeight: "100%", display: "flex", flexWrap: { md: "wrap", sm: "wrap", xs: "nowrap" }, flexDirection: { xs: "column", md: "row", sm: "row" }, justifyContent: { md: "start", sm: "start", xs: "start" }, alignItems: { xs: "center", md: "start", sm: "start" }, gap: 2 }} >
                    {offersData.map((offers, index) => (
                        <CardActionArea key={offers?.Code + index} sx={{
                            width: { xs: "100%", sm: "100%", md: "100%" }, display: "flex", justifyContent: "center", borderRadius: 2, border: {
                                xs: "", sm: `1px solid ${theme.palette.text.disabled

                                    }`
                            }
                        }}
                            onClick={() => {
                                navigator?.clipboard?.writeText(offers?.Code).then((res) => {
                                    toolTipActive("success", "Copied to Clipboard")
                                }).catch((err) => {
                                    toolTipActive("error", "Failed to Copy")

                                })
                            }}
                        >
                            <Box sx={{ width: "97%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", }} >
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "10px" }} >
                                    {/* <Typography>{"Coupon Code".toUpperCase()}:{offers.Code}</Typography>
                                    <Typography>{"Coupon Name".toUpperCase()}:{offers.Coupon_Name}</Typography> */}
                                    <Typography>{offers.Code}</Typography>
                                    <CardMedia
                                        image={require('../../../assests/images/prodOffer.png')}
                                        sx={{ width: "48px", height: "48px" }}
                                    />
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "4px" }} >
                                    <AutoAdjustText style={{ fontWeight: "bold", width: "100px", }} numLines={1} >{offers.Coupon_Name} </AutoAdjustText>|
                                    {/* <Typography>{"Discount".toUpperCase()}:{offers.Discount_Amount}{offers.Discount_Type === "P" && "%"}</Typography> */}
                                    <Box sx={{ color: theme.palette.text.secondary }}>
                                        {parse(offers.Description)}
                                    </Box>
                                </Box>
                            </Box>
                        </CardActionArea>
                    ))}
                </Box>}
                {!loading ? offersData.length === 0 &&
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5%" }} >
                        <CardMedia
                            image={require('../../../assests/images/prodOffer.png')}
                            sx={{ width: 100, height: 100, objectFit: "contain" }}
                        />
                        <Typography variant='h1' >NO OFFERS</Typography>
                    </Box>
                    :
                    Array(1).fill(1).map((e, index) =>
                    (<Box key={index} sx={{ width: { xs: "100%", sm: "100%", md: "100%" }, display: "flex", justifyContent: "center", borderRadius: 2, border: `0px solid ${theme.palette.input.borderColor}` }} >
                        <Skeleton variant='rectangular' sx={{ width: "100%", height: "100%" }} />
                    </Box>)
                    )
                }
            </Box>
        </Box>
    )
}
const FeaturesDetails = ({ productDetails = [] }) => {
    return (
        <Box>
            {
                productDetails[0]?.Features && parse(productDetails[0]?.Features)
            }
        </Box>
    )
}
const ProductDescriptionDetails = ({ productDetails = [] }) => {
    return (
        <Box>
            {productDetails[0]?.Product_Description &&
                parse(productDetails[0]?.Product_Description)
            }
        </Box>
    )
}

const SpecificationsDetails = ({ productDetails = [] }) => {
    return (
        <Box>
            {productDetails[0]?.Specifications &&
                parse(productDetails[0]?.Specifications)
            }
        </Box>
    )
}
const WarrantyDetails = ({ warrantyDetails = [] }) => {
    const theme = useTheme()
    return (
        <Box sx={{ width: "100%" }}>
            {warrantyDetails?.length >= 1 ?
                warrantyDetails.map((warrantyDetail, index) => {
                    return (
                        <Box key={index} sx={{ width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 1 }} >
                            <Typography sx={{ color: theme.palette.text.main, fontWeight: "bold" }} >{warrantyDetail?.partnerName}:</Typography>
                            <Typography sx={{ color: theme.palette.text.main }} >{warrantyDetail?.Warranty_Period}</Typography>
                        </Box>
                    )
                }) :
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Typography>No Warranty</Typography>
                </Box>
            }
        </Box>
    )
}