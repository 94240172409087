import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import cart from '../../../assests/images/paymentSuccess.png';
import { useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
const PaymentFailed = () => {
    const navigate = useNavigate()
    return (
        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: "100%", height: { xs: "80vh", md: '100vh', sm: '100vh' } }}>
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 1 }} >
                {/* <img src={cart} alt='cart' /> */}
                <CancelIcon sx={{transform:"scale(500%) translateY(-20px)"}} />
                {/* <Typography sx={{ fontWeight: "400", fontSize: { sm: '34px', md: '34px', xs: '16px' }, display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%' }}>
                    Thank you for your order!  </Typography> */}
                <Typography sx={{ fontWeight: "400", fontSize: { sm: '34px', md: '34px', xs: '16px' }, display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%' }}> Your order is Failed, Due to some reasons.   </Typography>
                <Box sx={{ width: { sm: "45%", md: '45%', xs: '100%' }, display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Typography sx={{ color: "grey", width: "100%", textAlign: "center", fontSize: { sm: '34px', md: '34px', xs: '16px' } }}>
                        Please try Payment in Orders Page 
                    </Typography>
                </Box>
                <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'row', gap: { md: 60, sm: 60, xs: '30px' }, mt: '30px' }}>
                    <Button onClick={() => {
                        navigate("/accounts/orders")
                    }} sx={{ color: "black", border: "1px solid grey", minWidth: { md: "200px", sm: '200px', xs: '100px' }, borderRadius: 2 }}  >Go to Orders</Button>
                </Box>
            </Box>
        </Box>

    );
}

export default PaymentFailed