import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from './Header';
import { Box, Grid, Paper, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import useScreenSize from '../../utils/ScreenWidthHight';

import Footer from './Footer';
import { Outlet, useLocation } from 'react-router-dom';
import ScrollToTop from '../../utils/ScrollToTop';
import { HomePagesModal } from './home';
import { GlobalContext } from '../../Context';
import DraggableCart from '../components/accounts/cart/DraggableCart';


const Retail = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [pageModalOpen, setPageModalOpen] = useState(false)
  const { logLoading } = useContext(GlobalContext)
  const pathname = useLocation().pathname

  useEffect(() => {
  }, [pageModalOpen])
  const customTheme = useMemo(() => {
    return createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: prefersDarkMode ? '#1976d2' : '#27B6CC', // Adjust according to the chosen theme
          light: prefersDarkMode ? '#63a4ff' : '#64b5f6',
          dark: prefersDarkMode ? '#004ba0' : '#1976d2',
          contrastText: '#ffffff', // White text
        },
        secondary: {
          main: prefersDarkMode ? '#dc004e' : '#f50057', // Adjust according to the chosen theme
          light: prefersDarkMode ? '#ff5c8d' : '#ff4081',
          dark: prefersDarkMode ? '#9a0036' : '#c51162',
          contrastText: '#ffffff', // White text
        },
        error: {
          main: '#f44336',
          light: '#e57373',
          dark: '#d32f2f',
          contrastText: '#ffffff', // White text
        },
        warning: {
          main: '#ff9800',
          light: '#ffb74d',
          dark: '#f57c00',
          contrastText: '#ffffff', // White text
        },
        info: {
          main: '#2196f3',
          light: '#64b5f6',
          dark: '#1976d2',
          contrastText: '#ffffff', // White text
        },
        success: {
          main: '#4caf50',
          light: '#81c784',
          dark: '#388e3c',
          contrastText: '#ffffff', // White text
        },
        text: {
          primary: prefersDarkMode ? '#ffffff' : '#212121', // Adjust according to the chosen theme
          secondary: '#757575', // Grey text
          disabled: '#bdbdbd', // Light grey text
          hint: '#bdbdbd', // Light grey text
          hover: "#27B6CC"

        },
        background: {
          default: prefersDarkMode ? '#121212' : '#ffffff', // Adjust according to the chosen theme
          paper: prefersDarkMode ? '#212121' : '#fafafa', // Adjust according to the chosen theme
        },
        input: {
          borderColor: '#27B6CC',
        },
        divider: '#757575', // Divider color
        action: {
          active: prefersDarkMode ? '#27B6CC' : '#27B6CC', // Adjust according to the chosen theme
          hover: prefersDarkMode ? '#424242' : '#eeeeee', // Adjust according to the chosen theme
          selected: '#C0C0C0', // Grey selected color
          disabled: '#616161', // Grey disabled color
          disabledBackground: prefersDarkMode ? '#424242' : '#f5f5f5', // Adjust according to the chosen theme
        },
        extra: {
          shadowColorOne: prefersDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.20)',
          linkcolor: '#000000',
          primary: "#27B6CC",
          errorMessage: '#FF0000',
          couponCode: '#134953',
          categoryBorder: '#DEDEDE',
          todaypricebgcolor: '#546E7A',
          newsAndEventNewsType: '#FFC0CB',
          contrastText: '#FFFFFF'
        },
        headerbutton: {
          textcolorone: '#000000',
          backgroundcolorone: '#f5f5f5',

        },
        header: {
          backgroundcolor: prefersDarkMode ? '#121212' : '#fafafa'
        },




      },
      typography: {
        fontFamily: [
          'Mulish'
        ].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              // Default button styles
              '&:hover': {
                backgroundColor: '#1f90a1', // Change this to your desired hover color
              },
            },
          },
        },
      }
    })
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={customTheme}>
      <ScrollToTop />

      <Grid container >
        {/* First Row */}
        <Grid item xs={12}>
          {/* <Paper style={{opacity:logLoading?.2:1}}> */}
          <Header pageModalOpen={pageModalOpen} setPageModalOpen={setPageModalOpen} type='retail' />
          {/* </Paper> */}
        </Grid>
        {/* Second Row */}
        <Grid item xs={12}>
          <Paper sx={{ paddingTop: { xs: 9, sm: "60px" }, opacity: logLoading ? .2 : 1, minHeight: "100vh" }}>
            {/* Content for the second row */}
              <Outlet />
            {!(pathname.includes("/retail/cart") || pathname.includes("/retail/checkout")) && (
              <div style={{ position: 'fixed',top:77,left:0, zIndex: 999 }}>
                <DraggableCart />
              </div>
            )}
            <HomePagesModal setPageModalOpen={setPageModalOpen} pageModalOpen={pageModalOpen} theme={customTheme} />
            <Footer />
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Retail;