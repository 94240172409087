import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { boomiApi, nodeApi, nodeCudApi, urlGenarator } from '../../CommonAxios';

const initialState = {
    orders: [],
    loading: false,
    error: "",
    products: []
}

export const fetchProductDetails = createAsyncThunk("orders/fetchProductDetails", async (formData) => {
    const response = await nodeApi.post("/Order/Retail_Item_Details", formData);
    const data = response?.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
    } else {
        return data.results || [];
    }
});

export const fetchOrderDetails = createAsyncThunk("orders/fetchOrderHistory", async (formData) => {
    const response = await nodeApi.post("/Order/Retail_Order_Get", formData);

    const data = response?.data;
    if (data.Success_Response.Is_Data_Exist === "0") {
        return [];
    } else {
        return data.results || [];
    }
});


export const productFetch = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.get('/Order/Retail/Get/' + formData.id).then((res) => {
            if (res.data.length > 0) {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const Nibuspost_Order_History = (Order_Id) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.get('/Order/Retail/get-nimbus-tracking/' + Order_Id).then((res) => {
            if (res.data.status) {
                resolve({ status: true, data: res.data.data })
            }
            else {
                resolve({ status: false, error: "Can't Found Data" })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const groceryProductFetch = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Order/Grocery_Order_Item_Details', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}



export const OrderInvoiceAction = async (formData) => {
    return new Promise(async (resolve, reject) => {
        await boomiApi.post(`/Order/Invoice_Get`, formData)
            .then(response => {
                let successResponse = response.data;
                if (response.data?.Is_Data_Exist === "0") {
                    resolve({ status: false, data: response.data })
                }
                else
                    resolve({ status: true, data: response.data })
            })
            .catch(error => {
                // updateStates(false, null);
                resolve({ status: false, error: error.message })
            })
    })
};
export const RetailOrderInvoiceAction = async (OrderId, formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.get(`/Order/Retail/Invoice-Get/${OrderId}`)
            .then(response => {
                if (response.status === 200) {
                    resolve({ status: true, data: response.data })
                }
                else {
                    resolve({ status: false, data: response.data })
                }
            })
            .catch(error => {
                // updateStates(false, null);
                resolve({ status: false, error: error.message })
            })
    })
};


export const orderStatusUpdate = (formData) => {
    return new Promise(async (resolve, reject) => {
        await boomiApi.put('/Order/Status_Update', formData).then((res) => {
            if (res.data.Response_Status === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}
export const OrderCancelAndReturnUpdate = (Order_Id, formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post(`/Order/retail-status-cr/${Order_Id}`, formData).then((res) => {
            if (res.data.code === "Success") {
                resolve({ status: true, data: res.data })
            }
            else
                resolve({ status: false, data: res.data })
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const Get_Product_Sizes_From_Partner = (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post('/Partners/Product_Retail_Size_Available', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            }
            else
                resolve({ status: false, data: [] })
        })
    })
}

export const invoiceGet = (formData) => {
    return new Promise(async (resolve, reject) => {
        await boomiApi.post('/Order/Invoice_Get', formData).then((res) => {
            if (res.data.Success_Response.Response_Code == "200") {
                resolve({ status: true, data: res.data.results })
            }
            else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

export const productSearch = (formData, pagination) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Order/Retail/Get', pagination), formData)
            .then((res) => {
                if (res.data.Success_Response.Is_Data_Exist === "1") {
                    resolve({ status: true, data: res.data.results, pagination: res.data.Pagination });
                } else {
                    resolve({ status: false, data: [] });
                }
            })
            .catch((err) => {
                resolve({ status: false, error: err.message });
            });
    });
};
export const groceryProductSearch = (formData, pagination) => {
    return new Promise(async (resolve, reject) => {
        await nodeApi.post(urlGenarator('/Order/Grocery_Order_Get', pagination), formData)
            .then((res) => {
                if (res.data.Success_Response.Is_Data_Exist === "1") {
                    resolve({ status: true, data: res.data.results, pagination: res.data.Pagination });
                } else {
                    resolve({ status: false, data: [] });
                }
            })
            .catch((err) => {
                resolve({ status: false, error: err.message });
            });
    });
};


export const Order_Exchange_Action = (Order_Id, formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeCudApi.post(`/Order/retail-exchange/${Order_Id}`, formData).then((res) => {
            if (res.data.code === "Success") {
                resolve({ status: true, data: res.data })
            } else {
                resolve({ status: false, error: res.data })
            }
        }).catch((err) => {
            resolve({ status: false, error: err.message })
        })
    })
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrderDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchOrderDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
                state.error = "";
            })
            .addCase(fetchOrderDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(fetchProductDetails.pending, (state, action) => {
                state.loading = true
            }).addCase(fetchProductDetails.fulfilled, (state, action) => {
                state.loading = false
                state.products = action.payload
            }).addCase(fetchProductDetails.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    }
});

export default ordersSlice.reducer;