import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Get_Product_Sizes_From_Partner, Order_Exchange_Action } from '../../../../store/slices/accounts/orders/OrdersSlice';
import { GlobalContext } from '../../../../Context'
import { ArrowBack } from '@mui/icons-material';
import * as Yup from 'yup'
import { fetchWalletDetails } from '../../../../store/slices/accounts/WalletSlice';
import { useSelector } from 'react-redux';
import razorpayPay from '../../../../utils/Payment';
import useRazorpay from 'react-razorpay';
import useScreenSize from '../../../../utils/ScreenWidthHight';

const style = {
    position: 'absolute',
    top: '30%',
    left: { xs: "7.5%", md: '30%' },
    width: { xs: "70%", md: "40%" },
    height: { xs: "50%", md: "40%" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: "20px",
    color: "white",
};

const ExchangeModal = ({ Order_Details = {}, Modal_Open = false, Handle_Modal_Open = (() => { }), productsCall = (() => { }) }) => {

    const [Check_Boxs, set_Check_Box] = useState({ Replace_Same_Product: true, Exchange_With_Different_Size: false, Selected_Button: "Replace_Same_Product" })
    const [Transaction_Type, set_Transaction_Type] = useState({ wallet: true, Payment_GateWay: false, Selected_Transaction: "wallet" })
    const Check_Boxes_Ref = useRef([])
    const [sizes, setSizes] = useState([])
    const [navigationConditon, setNavigationCondition] = useState(false)
    const [selectedSize, setSelectedSize] = useState(0)
    const { toolTipActive } = useContext(GlobalContext)
    const [walletDetails, setWalletDetails] = useState({})
    const [Razorpay, isLoaded] = useRazorpay();
    const winsize = useScreenSize()
    const auth = useSelector(state => state.auth.user)
    const [paymentLoading, setPaymentLoading] = useState(false)

    useEffect(() => {
        if (Order_Details?.Partner_Product_Id) {
            Get_Product_Sizes_From_Partner_Call({ Partner_Product_Id: Order_Details.Partner_Product_Id })
        }
    }, [Order_Details])

    useEffect(() => {
        if (auth.User_Details_Id)
            fetchWalletDetailsCall({ "User_Details_Id": auth.User_Details_Id })
    }, [auth])

    useEffect(() => {
        handleLocalClose()
    }, [winsize.screenSize.width])

    useEffect(() => {
        if (Check_Boxs.Replace_Same_Product) {
            scrollToSection("Replace_Same_Product")
            setTimeout(() => {
                scrollToSection("Select_Size")
            }, 500)

        }
        else if (Check_Boxs.Exchange_With_Different_Size) {
            scrollToSection("Exchange_With_Different_Size")
            setTimeout(() => {
                scrollToSection("Select_Size")
            }, 500)
        }
    }, [Check_Boxs, Check_Boxes_Ref])

    useEffect(() => {
        scrollToSection("Select_Size")
        if (Check_Boxes_Ref.current["Modal"]) {
            const modalStyle = Check_Boxes_Ref.current["Modal"].style
            modalStyle.backgroundImage = "linear-gradient(30deg,#272727,#272747)"
        }

    }, [Check_Boxes_Ref.current["Modal"]])

    const handleLocalClose = (forceClose = false) => {
        try {
            if (((!paymentLoading) && navigationConditon) || forceClose) {
                setPaymentLoading(false)
                Handle_Modal_Open()
                set_Check_Box(state => {
                    let boxes = { Replace_Same_Product: true, Exchange_With_Different_Size: false, Selected_Button: "Replace_Same_Product" }
                    return (boxes)
                })
                formik.resetForm()
                set_Transaction_Type({ wallet: true, Payment_GateWay: false, Selected_Transaction: "wallet" })
                setSelectedSize(0)
            } else {
                if (!navigationConditon) {
                    setNavigationCondition(true)
                    setPaymentLoading(false)
                }
            }
        }
        catch {

        }
    }


    const Get_Product_Sizes_From_Partner_Call = async (formData) => {
        const response = await Get_Product_Sizes_From_Partner(formData)
        if (response.status) {
            setSizes(response.data)
        } else {
            setSizes([])
        }
    }

    const fetchWalletDetailsCall = async (formData) => {
        const response = await fetchWalletDetails(formData)
        if (response.status) {
            setWalletDetails(response.data[0])
        } else {
            setWalletDetails({})
        }
    }



    const scrollToSection = (section) => {
        if (Check_Boxes_Ref.current["Scroll_Control"] && Check_Boxes_Ref.current[section]) {
            const Scroll_Control = Check_Boxes_Ref.current["Scroll_Control"].style;
            const scrollStyles = Check_Boxes_Ref.current[section];
            Scroll_Control.height = "80%"
            Scroll_Control.transitionDuration = ".7s"
            scrollStyles.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };



    const Pending_Animation = () => {
        setPaymentLoading(true)
        scrollToSection("Exchange_Success")
        if (Check_Boxes_Ref.current["Modal"]) {
            const modalStyle = Check_Boxes_Ref.current["Modal"].style;
            const Exchange_Pending_ImageStyles = Check_Boxes_Ref.current["Exchange_Pending_Image"].style;
            modalStyle.backgroundImage = "linear-gradient(30deg, #f5f25d, #6b6a19)";
            modalStyle.transitionDuration = ".7s";  // Transition background change
            const typoStyles = Check_Boxes_Ref.current["Exchange_Success_Message"].style
            typoStyles.position = "relative"
            typoStyles.bottom = "-100px"
            Exchange_Pending_ImageStyles.opacity = "1"
            Exchange_Pending_ImageStyles.display = "block"
        }
    }

    const Success_Animation = (onClose = (() => { })) => {
        if (Check_Boxes_Ref.current["Modal"]) {
            const modalStyle = Check_Boxes_Ref.current["Modal"].style;
            const Exchange_Pending_ImageStyles = Check_Boxes_Ref.current["Exchange_Pending_Image"].style;
            const typoStyles = Check_Boxes_Ref.current["Exchange_Success_Message"].style
            Exchange_Pending_ImageStyles.opacity = 0
            Exchange_Pending_ImageStyles.transitionDuration = ".9s"
            typoStyles.bottom = "-70px"
            typoStyles.transitionDuration = "1s";
            setTimeout(() => {
                Check_Boxes_Ref.current["Exchange_Pending_Image"].src = require('../../../../assests/images/exchangesuccess.png')
                Exchange_Pending_ImageStyles.opacity = 1
                Exchange_Pending_ImageStyles.transitionDuration = ".9s"
                Check_Boxes_Ref.current["Exchange_Success_Message"].innerHTML = "Exchange Successfully";
                modalStyle.backgroundImage = "none";
                modalStyle.backgroundColor = "#154d1f";
                modalStyle.transitionDuration = "2s";
                setTimeout(() => {
                    productsCall()
                    onClose()
                    handleLocalClose(true)
                }, 5000)
            }, 1000);
        }
    }
    const Failuer_Animation = (onClose = (() => { })) => {
        try {
            if (Check_Boxes_Ref.current["Modal"] && Check_Boxes_Ref.current["Exchange_Pending_Image"]) {
                const modalStyle = Check_Boxes_Ref.current["Modal"].style;
                const Exchange_Pending_ImageStyles = Check_Boxes_Ref.current["Exchange_Pending_Image"].style;
                const typoStyles = Check_Boxes_Ref.current["Exchange_Success_Message"].style
                Exchange_Pending_ImageStyles.opacity = 0
                Exchange_Pending_ImageStyles.transitionDuration = ".9s"
                typoStyles.bottom = "-70px"
                typoStyles.transitionDuration = "1s";
                setTimeout(() => {
                    Check_Boxes_Ref.current["Exchange_Pending_Image"].src = require('../../../../assests/images/exchangecancel.png')
                    Exchange_Pending_ImageStyles.opacity = 1
                    Exchange_Pending_ImageStyles.transitionDuration = ".9s"
                    Check_Boxes_Ref.current["Exchange_Success_Message"].innerHTML = "Exchange Failed";
                    modalStyle.backgroundImage = "none";
                    modalStyle.backgroundColor = "#661020";
                    modalStyle.transitionDuration = "2s";
                    const closeTimeOut = setTimeout(() => {
                        onClose()
                        handleLocalClose(true)
                    }, 5000)
                    return () => clearTimeout(closeTimeOut)
                }, 1000);
            }
        }
        catch (err) {
            console.log(err)
            handleLocalClose(true)
        }
    }

    const Order_Exchange_Action_Call = async (Order_Id, formData) => {
        Pending_Animation()
        const response = await Order_Exchange_Action(Order_Id, formData)
        if (response.status) {
            Success_Animation()
        } else {
            Failuer_Animation()
        }
    }

    const Place_Order = (Amount) => {
        if (Check_Boxs.Replace_Same_Product && Amount <= 0) {
            const formData = {
                isExchangeSameProduct: Check_Boxs.Replace_Same_Product,
                "Reason_For": formik.values.reasonType,
                "Reason_Info": formik.values.comment,
            }
            Order_Exchange_Action_Call(Order_Details.Order_Id, formData)
        }
        else if (Transaction_Type.wallet) {
            const formData = {
                isExchangeSameProduct: Check_Boxs.Replace_Same_Product,
                "Reason_For": formik.values.reasonType,
                "Reason_Info": formik.values.comment,
                Wallet: {
                    "Wallet_Amount": 0,
                    "Wallet_Id": walletDetails.Wallet_Id
                },
                "Payment_By": Transaction_Type.wallet ? "wallet".toUpperCase() : "online".toUpperCase(),
                "Size": sizes[selectedSize].Product_Size_Id,
                "MRP": sizes[selectedSize].Partner_Selling_Price,
                "Selling_Price": sizes[selectedSize].Partner_Selling_Price
            }
            Order_Exchange_Action_Call(Order_Details.Order_Id, formData)
        }
        else
            razorpayPay(Razorpay, { user: auth, amount: Amount }, (status, response) => {
                if (status) {
                    // Update order status after payment is successful
                    const formData = {
                        isExchangeSameProduct: Check_Boxs.Replace_Same_Product,
                        "Reason_For": formik.values.reasonType,
                        "Reason_Info": formik.values.comment,
                        Wallet: {
                            "Wallet_Amount": 0,
                            "Wallet_Id": walletDetails.Wallet_Id
                        },
                        "Payment_By": auth.Email,
                        "ThirdPartyPayment": {
                            "Transaction_Amount": Amount,
                            "Transaction_Id": response.razorpay_payment_id,
                            "Transaction_By": auth.Email,
                            "Transcation_Status": "Pending"
                        },
                        "Size": sizes[selectedSize].Size,
                        "MRP": sizes[selectedSize].Partner_Selling_Price,
                        "Selling_Price": sizes[selectedSize].Partner_Selling_Price
                    }
                    Order_Exchange_Action_Call(Order_Details.Order_Id, formData)
                } else {
                    Pending_Animation()
                    setTimeout(() => {
                        Failuer_Animation()
                        toolTipActive("error", 'Payment failed, please try again.');
                    }, 4000)
                }
            });


    }

    const formik = useFormik({
        initialValues: {
            reasonType: "",
            comment: ""
        },
        validationSchema: Yup.object().shape({
            reasonType: Yup.string().required("Reason Type is Required"),
            comment: Yup.string().required("Reason Type is Required"),
        })
        ,
        onSubmit: async (values) => {
            if (Check_Boxs.Replace_Same_Product) {
                scrollToSection("Check_Order")
            } else
                scrollToSection("payment")
        }
    })


    const Amount_To_Pay = useMemo(() => {
        let amount = 0
        if (selectedSize)
            amount = Number((Order_Details.Quantity_Selling_Price) - (sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity))
        return amount.toFixed(2)
    }, [selectedSize, sizes, Order_Details])

    return (
        <Modal
            open={Modal_Open}
            onClose={() => handleLocalClose(false)}
        >

            <Box sx={{ ...style }} ref={el => Check_Boxes_Ref.current["Modal"] = el} >
                <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", overflow: "hidden", }} tabIndex={-1} >
                    <Box sx={{ width: "100%", minHeight: "100%", display: "flex", flexDirection: "row", overflow: "hidden" }} >
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", gap: 1, minWidth: "100%", width: "100%", maxWidth: "100%", minHeight: "100%", overflowY: "scroll", overflowX: "hidden" }}
                            ref={el => Check_Boxes_Ref.current["Select_Size"] = el}
                            tabIndex={-1}
                        >
                            <Box sx={{ height: "10%" }} >
                                <Typography sx={{ fontSize: "1.4rem" }} >Product Exchange</Typography>
                            </Box>
                            <Box sx={{ width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                <FormControl component="fieldset" sx={{ width: "100%" }} >
                                    <RadioGroup
                                        tabIndex={-1}
                                        value={Check_Boxs.Selected_Button}
                                        onChange={(e, value) => {
                                            set_Check_Box(state => {
                                                let boxes = { Replace_Same_Product: false, Exchange_With_Different_Size: false, Selected_Button: "" }
                                                boxes[value] = true
                                                boxes.Selected_Button = value
                                                return (boxes)
                                            })
                                        }}
                                        aria-label="replacement-options"
                                        ref={el => Check_Boxes_Ref.current["Selection_Group"] = el}
                                        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}
                                    >
                                        <FormControlLabel
                                            value="Replace_Same_Product"
                                            control={<Radio tabIndex={-1} />}
                                            label="Replace with Same Product"
                                        />
                                        <FormControlLabel
                                            value="Exchange_With_Different_Size"
                                            control={<Radio tabIndex={-1} />}
                                            label="Exchange with Different Size"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Box sx={{ width: "100%", height: "80%", flexDirection: "row", overflow: "hidden" }}
                                    ref={el => Check_Boxes_Ref.current["Scroll_Control"] = el}
                                    tabIndex={-1}

                                >
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}
                                        ref={el => Check_Boxes_Ref.current["Replace_Same_Product"] = el}
                                    >
                                        <Typography>Your Product will be replaced with same size and partner.</Typography>

                                        <Button variant='contained'
                                            tabIndex={-1}
                                            onClick={() => {
                                                toolTipActive("success", "Product Can Exchange")
                                                scrollToSection("Q&n")
                                                setSelectedSize(sizes.findIndex((value) => value.Product_Size_Id === Order_Details.Product_Size_Id))
                                            }}
                                            disabled={Number(sizes[sizes.findIndex((value) => value.Product_Size_Id === Order_Details.Product_Size_Id)]?.Availability_Stock) <= 0 || sizes.length === 0}
                                            sx={{ display: "flex", flexDirection: "column" }}
                                        >Continue
                                            {(Number(sizes[sizes.findIndex((value) => value.Product_Size_Id === Order_Details.Product_Size_Id)]?.Availability_Stock) <= 0 || sizes.length === 0) && <FormHelperText sx={{fontSize:8}} error > No Stock Available </FormHelperText>}
                                        </Button>

                                    </Box>
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                                        ref={el => Check_Boxes_Ref.current["Exchange_With_Different_Size"] = el}
                                        tabIndex={-1}
                                    >
                                        {sizes.length > 0 ?
                                            <Box sx={{ width: "100%", height: "70%", display: "flex", flexDirection: "column", alignItems: "flex-end" }} >
                                                <Box sx={{ width: "90%" }} >
                                                    <Typography>Select Sizes</Typography>
                                                </Box>
                                                <Box sx={{ width: "100%", height: "70%", display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1, overflow: "scroll", flexDirection: "row" }} >
                                                    {
                                                        sizes.map((size, index) => {
                                                            return (
                                                                <Button
                                                                    key={index} variant={selectedSize === index ? 'contained' : "outlined"}
                                                                    sx={{ width: "auto", display: "flex", flexDirection: "column" }}
                                                                    onClick={() => {
                                                                        if (selectedSize !== index)
                                                                            setSelectedSize(index)
                                                                    }}
                                                                    disabled={size.Product_Size_Id === Order_Details.Product_Size_Id}
                                                                    tabIndex={-1}
                                                                >
                                                                    <Typography>
                                                                        {size.Size}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: ".6rem" }} >
                                                                        {size.Size_Measurement}
                                                                    </Typography>
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                            :
                                            <Typography sx={{ color: "red", }} >
                                                No Sizes Available
                                            </Typography>
                                        }
                                        {
                                            sizes.length > 0 &&
                                            <Button variant='contained'
                                                tabIndex={-1}
                                                disabled={(Number(sizes[selectedSize]?.Availability_Stock) === 0) || sizes[selectedSize]?.Product_Size_Id === Order_Details.Product_Size_Id}
                                                onClick={() => {
                                                    if ((Order_Details.Quantity_Selling_Price) === (sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity)) {
                                                        toolTipActive("success", "Product Can Exchange")
                                                        scrollToSection("Q&n")
                                                    }
                                                }}
                                            >Continue</Button>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            tabIndex={-1}
                            sx={{ minWidth: "100%", width: "100%", maxWidth: "100%", height: "100%", overflowY: "scroll", overflowX: "hidden" }}
                            ref={el => Check_Boxes_Ref.current["Q&n"] = el}
                        >
                            <Box sx={{ width: "100%", height: "10%", paddingY: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", position: "relative" }} >
                                <Box sx={{ width: "100%", cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: "25%", left: "3%" }}
                                    onClick={() => {
                                        scrollToSection("Select_Size")
                                        formik.values.reasonType = ""
                                        formik.values.comment = ""
                                    }} >
                                    <ArrowBack />
                                </Box>
                                <Typography sx={{ fontSize: "1.4rem" }} > Reason</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95%" }}>
                                    <FormControl
                                        tabIndex={-1}
                                        fullWidth
                                        error={formik.touched.reasonType && Boolean(formik.errors.reasonType)}
                                    >
                                        <InputLabel tabIndex={-1}>Reason Type</InputLabel> {/* Add tabIndex={-1} to InputLabel */}
                                        <Select
                                            label="Reason Type"
                                            name="reasonType"
                                            value={formik.values.reasonType}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            sx={{ color: "white" }}
                                            tabIndex={-1}
                                            inputProps={{ tabIndex: -1 }}
                                        >
                                            <MenuItem value={""} tabIndex={-1}>Select Reason Type</MenuItem>
                                            <MenuItem value={"Product Delivered with Damage"} tabIndex={-1}>Product Delivered with Damage</MenuItem>
                                            <MenuItem value={"Size or Measurement not fit"} tabIndex={-1}>Size or Measurement not fit</MenuItem>
                                            <MenuItem value={"Recevied Delivery with Open"} tabIndex={-1}>Recevied Delivery with Open Package</MenuItem>
                                            <MenuItem value={"Other Reason"} tabIndex={-1}>Other Reason</MenuItem>
                                        </Select>
                                        {formik.touched.reasonType && formik.errors.reasonType && (
                                            <FormHelperText tabIndex={-1}>{formik.errors.reasonType}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95%" }}>
                                    <TextField
                                        label="comment"
                                        name="comment"
                                        value={formik.values.comment}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        inputProps={{
                                            style: { color: "white" },
                                            tabIndex: -1,  // This ensures textarea inside TextField is skipped
                                        }}
                                        tabIndex={-1}
                                    />
                                </Box>

                                <Box sx={{ width: "95%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <Button onClick={formik.handleSubmit} tabIndex={-1}>
                                        Submit
                                    </Button>
                                </Box>
                            </Box>

                        </Box>
                        <Box
                            tabIndex={-1}
                            sx={{ minWidth: "100%", width: "100%", maxWidth: "100%", height: "100%", overflowY: "scroll", overflowX: "hidden" }}
                            ref={el => Check_Boxes_Ref.current["payment"] = el}
                        >
                            <Box sx={{ width: "100%", height: "10%", paddingY: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", position: "relative" }} >
                                <Box sx={{ width: "100%", cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: "25%", left: "3%" }}
                                    onClick={() => { scrollToSection("Q&n") }} >
                                    <ArrowBack />
                                </Box>
                                <Typography sx={{ fontSize: "1.4rem" }} >Payment Section</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "90%", width: "100%" }} >
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", }} >
                                    <Typography sx={{ width: "100%" }} >Payment Details</Typography>
                                    <Box sx={{ width: "90%", display: "flex", flexDirection: "column", rowGap: 1 }} >
                                        <Typography>Amount Paid To Before Purchase: Rs.{Order_Details.Quantity_Selling_Price}</Typography>
                                        <Typography>Total Amount For This Product: Rs.{sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity}</Typography>
                                        <Typography>Remaining Amount Need to Pay: Rs.{(Order_Details.Quantity_Selling_Price) - (sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <Typography sx={{ width: "100%" }}>Select Payment Type</Typography>
                                    <RadioGroup
                                        tabIndex={-1}
                                        value={Transaction_Type.Selected_Transaction}
                                        onChange={(e, value) => {
                                            set_Transaction_Type((state) => {
                                                let boxes = { wallet: false, Payment_GateWay: false, Selected_Transaction: "" };
                                                boxes[value] = true;
                                                boxes.Selected_Transaction = value;
                                                return boxes;
                                            });
                                        }}
                                        sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                    >
                                        <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <FormControlLabel
                                                label="Use Wallet"
                                                value={"wallet"}
                                                control={<Radio tabIndex={-1} />} // Add tabIndex here
                                                sx={{ fontSize: { xs: ".8rem", sm: "1rem" } }}
                                                tabIndex={-1} // Add tabIndex to FormControlLabel
                                            // disabled={(walletDetails?.Remaining_Balance < Number(Amount_To_Pay)) || Number(Amount_To_Pay) === 0}
                                            />
                                            {Transaction_Type.wallet ? (
                                                <Typography sx={{ fontSize: { xs: ".8rem", sm: 12 } }}>
                                                    Used Amount {Amount_To_Pay}
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ fontSize: 12 }}>
                                                    Amount in Wallet {walletDetails?.Remaining_Balance}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <FormControlLabel
                                                label="Online Payment"
                                                value={"Payment_GateWay"}
                                                control={<Radio tabIndex={-1} />} // Add tabIndex here
                                                sx={{ fontSize: { xs: ".8rem", sm: "1rem" } }}
                                                disabled={Number(Amount_To_Pay) === 0}
                                                tabIndex={-1} // Add tabIndex to FormControlLabel
                                            />
                                            <Typography sx={{ fontSize: { xs: ".8rem", sm: 12 } }}>{"RazorPay"}</Typography>
                                        </Box>
                                    </RadioGroup>
                                </Box>

                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Button onClick={() => { scrollToSection("Check_Order") }} tabIndex={-1} >Check Order</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {formik.values.reasonType &&
                        <Box
                            tabIndex={-1}
                            sx={{ minWidth: "100%", minHeight: "100%", overflowY: "scroll", overflowX: "hidden" }}
                            ref={el => Check_Boxes_Ref.current["Check_Order"] = el}
                        >
                            <Box sx={{ width: "100%", height: "10%", paddingY: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", position: "relative" }} >
                                <Box sx={{ width: "100%", cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: "25%", left: "3%" }}
                                    onClick={() => { Check_Boxs.Replace_Same_Product ? scrollToSection("Q&n") : scrollToSection("payment") }} >
                                    <ArrowBack />
                                </Box>
                                <Typography sx={{ fontSize: "1.4rem" }} >Check Order</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "80%", width: "100%" }} >

                                {(sizes.length > 0) &&
                                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }} >
                                        <Typography>{Check_Boxs.Selected_Button}</Typography>
                                        <Typography>Product Name: {Order_Details.Product_Name}</Typography>
                                        {
                                            sizes[selectedSize]?.Size ? <Typography>Size: {sizes[selectedSize]?.Size} {" "} {sizes[selectedSize]?.Size_Measurement}</Typography>
                                                :
                                                <Typography>Size: {Order_Details?.Size} {" "} {Order_Details?.Size_Measurement}</Typography>}
                                        <Typography>Partner: {Order_Details.Partner_Name}</Typography>
                                        <Typography>Price: Rs.{Order_Details.Quantity_Selling_Price} /- </Typography>
                                    </Box>
                                }
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                    <Button tabIndex={-1} onClick={() => Place_Order(((Order_Details.Quantity_Selling_Price) - (sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity)))} >Exchange  {Check_Boxs.Exchange_With_Different_Size && "With "} {Check_Boxs.Exchange_With_Different_Size && ((Order_Details.Quantity_Selling_Price) - (sizes[selectedSize]?.Partner_Selling_Price * Order_Details.Quantity))} </Button>
                                </Box>
                            </Box>
                        </Box>}

                    <Box
                        sx={{ minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}
                        ref={el => Check_Boxes_Ref.current["Exchange_Success"] = el}
                    >
                        <img
                            src={require('../../../../assests/images/pending.png')}
                            style={{ width: 50, height: 50 }}
                            ref={el => Check_Boxes_Ref.current["Exchange_Pending_Image"] = el}
                        />

                        <Typography sx={{ color: "white", fontSize: "1.5rem" }}
                            ref={el => Check_Boxes_Ref.current["Exchange_Success_Message"] = el}
                        >Processing...</Typography>
                    </Box>

                </Box>
            </Box>

        </Modal >
    )
}

export default ExchangeModal