import * as React from "react";
import { Autocomplete, Box, Button, Grid, Link, TextField, Typography, Skeleton } from "@mui/material";
import CartProductDetails from "./CartProductDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartAddressUpdateAction, getCart, getInactiveCart } from "../../../store/slices/accounts/cart/CartSlice";
import { useTheme } from "@emotion/react";
import { GlobalContext } from "../../../Context";

const CartPartner = ({ Type = "RETAIL", getActiveCartCall = (() => { }), cartProducts, partner, placeOrder, fgPartnerOrderRequiredAmount }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { toolTipActive } = React.useContext(GlobalContext);
    const locations = useSelector(state => state.addressProfiles.addressProfile);
    const [optionSelected, setOptionSelected] = React.useState({})
    const [inActiveProducts, setInactiveProducts] = React.useState()
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.auth)
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getInactiveCartCall({ User_Email_Id: userDetails.user.Email_Id })
    }, [userDetails])

    const getInactiveCartCall = async (formData) => {
        const response = await getInactiveCart(formData)
        if (response.status) {
            setInactiveProducts(response.data)
        }
        else {
            setInactiveProducts([])
        }
    }

    const getPartnerOrderPrice = (items) => {
        let amount = 0;
        items.Products.map(product => (
            amount = amount + (Number(product.Partner_Selling_Price) * Number(product.Quantity))
        ));
        return amount.toFixed(2);
    };

    const deliveryValueCaluculation = (orderValue, arrayData) => {
        let response = { charge: 0, message: "" };
        function sortResults(input, prop, asc) {
            input.sort(function (a, b) {
                if (a[prop]) {
                    if (asc) {
                        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                    } else {
                        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                    }
                } else {
                    throw new Error(prop + ' is not a exist!');
                }
            });
            return input;
        }
        try {
            let data = sortResults(JSON.parse(arrayData), "value", true);
            for (let i = 0; i < data.length; i++) {
                if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
                    let message = "";
                    let charge = 0;
                    if (i == 0 || i == data.length - 1) {
                        charge = data[i].charge
                    }
                    else {
                        charge = data[i - 1].charge
                    }
                    if (orderValue < data[i].value && i < data.length - 1) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                            else
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
                        }
                    } else if (orderValue < data[i].value) {
                        if (data[data.length - 1].charge == 0) {
                            message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                        } else {
                            if (i + 1 == data.length - 1)
                                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                        }
                    }
                    response = { charge: charge, message: message };
                    break;
                }
            }
        } catch (error) {
        }
        return response
    }

    const getDeliveryPrice = (items) => {
        let extraCharge = 0;
        let amount = 0;
        items.Products.map(product => {
            amount = amount + (Number(product.Shipping_Amount ? product.Shipping_Amount : 0) * Number(product.Quantity))
        });
        if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
            extraCharge = deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount).charge;
        }
        return (amount + extraCharge).toFixed(2);
    };

    const totalPrices = React.useMemo(() => {
        let totalPrice = 0;

        (partner.Products || []).forEach(item => {
            totalPrice += item.Partner_Selling_Price * item.Quantity;
        });
        totalPrice += Number(getDeliveryPrice(partner));
        return parseFloat(totalPrice.toFixed(1));
    }, [partner]);

    const retailTotalPrice = React.useMemo(() => {
        let totalPrice = 0;
        (cartProducts || []).forEach(item => {
            totalPrice += item.Partner_Selling_Price * item.Quantity;
            // totalPrice += Number(getDeliveryPrice(partner));
        })
        return parseFloat(totalPrice.toFixed(1));
    }, [cartProducts])

    React.useEffect(() => {
        const fetchData = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        };
        fetchData();
    }, [partner]);

    // React.useEffect(() => {
    //     setLoading(false);
    // }, [partner]);


    React.useEffect(() => {
        const data = locations.filter((shippingAddress) => (shippingAddress.id == partner.User_Shipping_Address_Id));
        if (data.length > 0) {
            setOptionSelected(data[0])
        } else {
            setOptionSelected({})
        }
    }, [locations, partner])

    const handleUpdateAddress = async (e, value) => {
        if (value?.link) {
            navigate('/accounts/address')
        } else {
            const response = await cartAddressUpdateAction({ Business_Type: "GROCERY", Partner_Details_Id: partner.Partner_Details_Id, User_Address_Id: value?.id, User_Email_Id: userDetails.user.Email_Id })
            if (response.status) {
                dispatch(getCart({ User_Email_Id: userDetails?.user?.Email_Id, Business_Type: "GROCERY" }));
            } else {
                toolTipActive("error", 'we are not serving to the selected address.');
            }
        }

    };
    const isOptionEqualToValue = (option, value) => {
        return option?.city === value?.city && option?.street === value?.street && option?.pincode === value?.pincode;
    };

    return (
        <Grid item xs={12} >
            <Box sx={{ display: 'flex', justifyContent: { xs: "space-between", md: 'right' }, margin: 3 }}>
                {loading ? (
                    <>
                        <Skeleton variant="text" width={150} height={30} sx={{ marginRight: 2 }} />
                        <Skeleton variant="rectangular" width={150} height={36} />
                    </>
                ) : (
                    <>
                        <Box sx={{ marginRight: "13%", marginTop: "5px" }}>
                            <Typography sx={{ fontWeight: "bold" }}>Total &#8377; {Type.toUpperCase() === "grocery".toUpperCase() ? totalPrices : retailTotalPrice}</Typography>
                        </Box>
                        {Type.toUpperCase() === "grocery".toUpperCase() && <Button variant="outlined" onClick={() => { placeOrder(partner.Partner_Details_Id) }}>PLACE ORDER</Button>}
                    </>
                )}
            </Box>

            {partner?.Business_Type == "GROCERY" && <>
                <Box sx={{ justifyContent: "center", marginBottom: "35px", marginTop: "5px" }}>

                    <Autocomplete
                        onChange={handleUpdateAddress}
                        name="Address"
                        label="Address"
                        size="medium"
                        fullWidth
                        variant="outlined"
                        clearIcon={null}
                        isOptionEqualToValue={() => {
                            return true
                        }}
                        options={[...locations, { title: "Add New Address", link: true }] || []}
                        getOptionLabel={(option) => option?.link ? `${option?.title}` : option?.city ? `${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}` : ""}
                        sx={{ width: { md: "400px", sm: '400px', xs: '363px' }, maxWidth: "100%", ml: { xs: '6px', md: 'unset', sm: 'unset' } }}
                        renderOption={(props, option) => {
                            if (option?.link)
                                return (
                                    <Typography key={option?.id} {...props} sx={{ color: theme.palette.primary.main }} >
                                        {option?.title}
                                    </Typography>
                                )
                            else
                                return (
                                    <Box key={option?.id}  {...props}>
                                        {`${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}`}
                                    </Box>
                                )
                        }}
                        defaultValue={optionSelected}
                        value={optionSelected}
                        renderInput={(params) => (
                            <TextField
                                placeholder="Select Location..."
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {/* No dropdown icon */}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    {(partner.ShowError && !partner.User_Shipping_Address_Id) &&
                        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                            Please select shipping address.
                        </Typography>}
                </Box>

                {deliveryValueCaluculation(Number(getPartnerOrderPrice(partner)), fgPartnerOrderRequiredAmount).charge > 0 &&
                    <Box sx={{ justifyContent: "center", marginBottom: "35px", marginTop: "5px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>Shipping Amount  ₹{deliveryValueCaluculation(Number(getPartnerOrderPrice(partner)), fgPartnerOrderRequiredAmount).charge}/-</Typography>
                        <Typography > {deliveryValueCaluculation(Number(getPartnerOrderPrice(partner)), fgPartnerOrderRequiredAmount).message}</Typography>
                    </Box>
                }
            </>
            }

            <Grid display="flex" flexWrap="wrap" >
                {Type.toUpperCase() === "grocery".toUpperCase() && (partner.Products || []).map((item, index) => (
                    <CartProductDetails
                        key={index}
                        Type={Type}
                        getActiveCartCall={getActiveCartCall}
                        product={item}
                        partner={partner}
                    />
                ))}

                {!(Type.toUpperCase() === "grocery".toUpperCase()) && cartProducts.map((item, index) => (
                    <CartProductDetails
                        Type={Type}
                        getActiveCartCall={getActiveCartCall}
                        key={index}
                        product={item}
                        partner={partner}
                    />
                ))}
            </Grid>
        </Grid>
    )
}

export default CartPartner
