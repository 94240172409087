import { useFormik } from "formik";
import * as Yup from 'yup'
import { OrderCancelAndReturnUpdate, orderStatusUpdate, productFetch } from "../../../../store/slices/accounts/orders/OrdersSlice";
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import { GlobalContext } from "../../../../Context";

export const CancelModalComp = ({ cancelModal, handelCancelModal, id, productsCall, isReturn, Order_Details }) => {

    const auth = useSelector(state => state.auth)
    const theme = useTheme()
    const { toolTipActive } = useContext(GlobalContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            reasonType: "",
            comment: ""
        },
        validationSchema: Yup.object().shape({
            reasonType: Yup.string().required("Reason Type is Required"),
            comment: Yup.string()
        })
        ,
        onSubmit: async (values) => {
            setLoading(true)
            const formData = {
                "Order_Status": isReturn ? "RETURN" : "CANCEL",
                "Reason_For": values.reasonType,
                "Reason_Info": values.comment,
            }
            const response = await OrderCancelAndReturnUpdate(Order_Details.Order_Id, formData)
            if (response.status) {
                productsCall(id)
                handelCancelModal()
                formik.resetForm()
                setErrorMessage("")
                toolTipActive("success", response.data.message)
            }
            else {
                toolTipActive("error", response.data.message)
                setErrorMessage(response.data.message)
            }
            setLoading(false)
        }
    })


    const style = {
        position: 'absolute',
        top: '30%',
        left: { xs: "7.5%", md: '37.5%' },
        width: { xs: "70%", md: "25%" },
        height: { xs: "50%", md: "40%" },
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        borderRadius: "20px"
    };


    return (
        <Modal
            open={cancelModal}
            onClose={() => {
                handelCancelModal()
                formik.resetForm()
            }}
        >
            <Box {...style} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", gap: 1 }}  >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }} >
                    <Typography>Order {isReturn ? "Return" : "Cancellation"} Form</Typography>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} >

                    <FormControl fullWidth error={formik.touched.reasonType && Boolean(formik.errors.reasonType)}>
                        <InputLabel>Reason Type</InputLabel>
                        <Select
                            label="Reason Type"
                            name="reasonType"
                            value={formik.values.reasonType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <MenuItem value={""}>Select Reason Type</MenuItem>
                            <MenuItem value={"By Mistakenly Places Order"}>By Mistakenly Places Order</MenuItem>
                            <MenuItem value={"Bought from other Partner"}>Bought from other Partner</MenuItem>
                            <MenuItem value={"Delivery Time is too long"}>Delivery Time is too long</MenuItem>
                            <MenuItem value={"Other Reason"}>Other Reason</MenuItem>
                        </Select>
                        {formik.touched.reasonType && formik.errors.reasonType && (
                            <FormHelperText>{formik.errors.reasonType}</FormHelperText>
                        )}
                    </FormControl>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} >
                    <TextField
                        label="comment"
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        multiline
                        rows={4}
                    />
                </Box>
                {errorMessage.length > 1 && <Typography color={theme.palette.error.main} >
                    {errorMessage},
                    Please Contact Support Team
                </Typography>}
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }} >
                    {loading ?
                        <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
                        :
                        <Button variant="contained" onClick={formik.handleSubmit} >Submit</Button>
                    }
                </Box>
            </Box>
        </Modal>
    )
}