import * as React from "react";
import { Autocomplete, Box, Card, Checkbox, Grid, IconButton, Link, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Skeleton } from "@mui/material";
import { useState } from "react";
import minus from "../../../../src/assests/images/minus.png";
import plus from "../../../../src/assests/images/plus.png";
import remove from "../../../../src/assests/images/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { getCart } from "../../../store/slices/accounts/cart/CartSlice";
import { cartAddressUpdateAction } from "../../../store/slices/accounts/cart/CartSlice";
import { deleteCartItem } from "../../../store/slices/accounts/cart/CartSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Context";
import AutoAdjustText from "../../../utils/AutoAdjustText";
import { cartRefresh } from "../../../store/slices/CommonSlice";
import useScreenSize from "../../../utils/ScreenWidthHight";

const CartProductDetails = ({ partner, product, Type, getActiveCartCall }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showGiftMessage, setShowGiftMessage] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const dispatch = useDispatch();
    const locations = useSelector(state => state.addressProfiles.addressProfile);
    const userDetails = useSelector(state => state.auth)
    const [optionSelected, setOptionSelected] = useState({})
    const [giftMessage, setGiftMessage] = useState("");
    const { toolTipActive } = React.useContext(GlobalContext);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(true);
    const winsize = useScreenSize()

    const breakPoints = React.useMemo(() => {
        const width = winsize.screenSize.width;

        return {
            is1600: width > 1600,
            is1500: width > 1500 && width <= 1600,
            is1400: width > 1400 && width <= 1500,
            is1300: width > 1300 && width <= 1400,
            is1200: width > 1200 && width <= 1300,
            is1100: width > 1100 && width <= 1200,
            is1000: width > 1000 && width <= 1100,
            is900: width > 900 && width <= 1000,
            is800: width > 800 && width <= 900,
            is700: width > 700 && width <= 800,
            is600: width > 600 && width <= 700,
        };
    }, [winsize.screenSize.width]);

    React.useEffect(() => {
        const fetchData = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        };
        fetchData();
    }, [product]);

    // React.useEffect(() => {
    //     setLoading(false);
    // }, [product]);

    React.useEffect(() => {
        setGiftMessage(product?.Gift_Message || "");
        if (product?.Gift_Message && product?.Is_Gift_Wrap_Available === 1)
            setShowGiftMessage(true);
    }, [product])

    const handleToggleGiftMessage = () => {
        setShowGiftMessage(!showGiftMessage);
        if (showGiftMessage && product?.Gift_Message)
            updateGiftMessage("")
    };

    const handleDecrementSize = async () => {
        if (product.Quantity != 0) {
            const response = await cartAddressUpdateAction({ User_Cart_Id: product.User_Cart_Id, Quantity: product.Quantity - 1 });

            if (response.status) {
                let formData = { User_Email_Id: userDetails?.user?.Email_Id }
                let apiurl = ""
                if (Type) {
                    formData["Business_Type"] = Type?.toUpperCase()
                    apiurl = "/Cart/Retail-Get"
                    getActiveCartCall(apiurl, formData)
                    dispatch(getCart({ apiurl, formData }));
                }
                dispatch(cartRefresh())

            }
        }
    };

    const handleIncrementSize = async () => {
        const response = await cartAddressUpdateAction({ User_Cart_Id: product.User_Cart_Id, Quantity: product.Quantity + 1 });

        if (response.status) {
            let formData = { User_Email_Id: userDetails?.user?.Email_Id }
            let apiurl = ""
            if (Type) {
                formData["Business_Type"] = Type?.toUpperCase()
                apiurl = "/Cart/Retail-Get"
                getActiveCartCall(apiurl, formData)
                dispatch(getCart({ apiurl, formData }));
            }
            dispatch(cartRefresh())
        }
    };

    const handleDelete = () => {
        setConfirmationOpen(true); // Open confirmation dialog
    };

    const handleDeleteConfirmation = async () => {
        const response = await deleteCartItem({ User_Cart_Id: product.User_Cart_Id });
        if (response.status) {
            let formData = { User_Email_Id: userDetails?.user?.Email_Id }
            let apiurl = ""
            if (Type) {
                formData["Business_Type"] = Type?.toUpperCase()
                apiurl = "/Cart/Retail-Get"
                getActiveCartCall(apiurl, formData)
                dispatch(getCart({ apiurl, formData }));
            }
            dispatch(cartRefresh())
            toolTipActive("error", "Successfully Item Deleted .");
        }
        setConfirmationOpen(false);
    };

    const handleMoveToWishList = async () => {
        const response = await deleteCartItem({ User_Cart_Id: product.User_Cart_Id, Move_Wish_List: true });
        if (response.status) {
            let formData = { User_Email_Id: userDetails?.user?.Email_Id }
            if (Type) {
                formData["Business_Type"] = Type?.toUpperCase()
            }
            dispatch(cartRefresh())
            getActiveCartCall(formData)
            dispatch(getCart(formData));
            toolTipActive("success", "Successfully Item Moved To Wish List .");
        }
        setConfirmationOpen(false);
    };

    const handleUpdateAddress = async (e, value) => {
        const response = await cartAddressUpdateAction({ User_Cart_Id: product.User_Cart_Id, User_Address_Id: value?.id })
        if (response.status) {
            let formData = { User_Email_Id: userDetails?.user?.Email_Id }
            if (Type) {
                formData["Business_Type"] = Type?.toUpperCase()
            }
            getActiveCartCall(formData)
            dispatch(getCart(formData));
        }
    };

    const handleGiftMessage = async (e) => {
        const value = e.target.value
        if (value.length < 300) {
            setGiftMessage(e.target.value);
        }
        else {
            toolTipActive("error", "max 300 characters only")
        }
    };

    const handleGiftBlur = (e) => {
        updateGiftMessage(e.target.value)
    }

    const updateGiftMessage = async (message) => {
        const response = await cartAddressUpdateAction({
            User_Cart_Id: product.User_Cart_Id,
            Gift_Message: message // Pass the updated gift message to the API call
        });

        if (response.status) {
            dispatch(getCart({ User_Email_Id: userDetails?.user?.Email_Id }));
        }
    }

    React.useEffect(() => {
        const data = locations.filter((shippingAddress) => (shippingAddress.id == product.User_Shipping_Address_Id));
        if (data.length > 0) {
            setOptionSelected(data[0])
        } else {
            setOptionSelected({})
        }
    }, [locations, product])

    const handleClear = () => {
        setValue('');
    };

    return (
        <>
            {Type?.toUpperCase() === "GROCERY" && <Grid item xs={12} sm={Type?.toUpperCase() === "RETAIL" ? 4 : 6} md={Type?.toUpperCase() === "RETAIL" ? 4 : 6}>
                <Card
                    sx={{
                        maxHeight: "auto",
                        maxWidth: "442px",
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: 2,
                        borderRadius: "12px",
                        border: "2px solid " + theme.palette.primary.main
                    }}
                >
                    <Box sx={{ width: "100%", maxWidth: "100%", height: '307px', }}>
                        {loading ? (
                            <Box sx={{ width: "100%", maxWidth: "100%", }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "10px",
                                    }}
                                >
                                    <Skeleton variant="rectangular" width="80px" height="60px" />
                                    <Box sx={{ flex: "1 1 auto", marginLeft: "10px" }}>
                                        <Skeleton variant="text" width="80%" height="30px" />
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Skeleton variant="text" width="60%" height="20px" />
                                            <Skeleton variant="circular" width="24px" height="24px" />
                                        </Box>
                                        <Skeleton variant="text" width="40%" height="20px" />
                                    </Box>
                                </Box>

                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                                <Box sx={{ width: "80px", height: "60px" }}>
                                    <img
                                        src={product.Product_Image}
                                        alt="Logo"
                                        onClick={() => { navigate(`/retail/products/${product.Product_Id}`) }}
                                        style={{ width: "80px", height: "60px", borderRadius: "6px", objectFit: "fill", cursor: "pointer" }}
                                    />
                                </Box>
                                <Box sx={{ flex: "1 1 auto", marginLeft: "10px" }}>
                                    <AutoAdjustText style={{ fontWeight: "bold", color: theme.palette.extra.cardShadowColorOne, fontSize: "18px", fontWeight: "bold" }} numLines={1}>
                                        {product.Product_Name}
                                    </AutoAdjustText>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography>
                                            &#8377; {product.Partner_Selling_Price} * {product.Quantity} = &#8377; {(product.Partner_Selling_Price * product.Quantity).toFixed(2)}
                                        </Typography>
                                        <IconButton onClick={handleDelete}>
                                            <img src={remove} alt="Logo" style={{ width: "24px", height: "24px", objectFit: "fill" }} />
                                        </IconButton>
                                    </Box>
                                    <Typography>
                                        Variant: {product.Size_Value} {product.Size_Measurement}
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        {loading ? (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        gap: '10px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: '15px',
                                    }}
                                >
                                    <Skeleton variant="circular" width="30px" height="30px" />
                                    <Skeleton variant="rectangular" width="30px" height="30px" />
                                    <Skeleton variant="circular" width="30px" height="30px" />
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '10px' }}>
                                    <Skeleton variant="text" width="60%" height="20px" />
                                </Box>
                            </>
                        ) : (
                            < Box sx={{ display: "flex", alignItems: "center", padding: "10px", justifyContent: product?.Is_Gift_Wrap_Available === 1 ? "space-between" : "center" }}>
                                {product?.Is_Gift_Wrap_Available === 1 && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox checked={showGiftMessage} onChange={handleToggleGiftMessage} />
                                        <Typography sx={{ ml: '8px' }}>Gift Wrap</Typography>
                                    </Box>
                                )}

                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: '5px' }}>
                                        <IconButton
                                            disabled={product.Quantity === 1 || product.Availability_Stock === 0}
                                            onClick={handleDecrementSize}
                                        >
                                            <img src={minus} alt="Minus Icon" style={{ width: "30px", height: "auto", cursor: "pointer" }} />
                                        </IconButton>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                border: "1px solid",
                                                borderColor: theme.palette.extra.cartBorderColor,
                                                margin: "0 5px",
                                            }}
                                        >
                                            {product.Quantity}
                                        </Typography>
                                        <IconButton
                                            disabled={product.Quantity === product.Availability_Stock || product.Availability_Stock === 0}
                                            onClick={handleIncrementSize}
                                        >
                                            <img src={plus} alt="Plus Icon" style={{ width: "30px", height: "auto", cursor: "pointer" }} />
                                        </IconButton>
                                    </Box>
                                    {(product.Quantity === product.Availability_Stock || product.Availability_Stock < 20) && (
                                        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                            Only {product.Availability_Stock} available.
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}

                        <Box sx={{ marginBottom: "12px", display: "flex", justifyContent: "center" }}>
                            {showGiftMessage && product?.Is_Gift_Wrap_Available === 1 && (
                                <TextField name="Gift message" label="Gift Message" value={giftMessage} multiline maxRows={3} inputProps={{ maxLength: 300 }}
                                    onChange={handleGiftMessage} onBlur={handleGiftBlur} fullWidth variant="outlined" size="medium" sx={{
                                        width: { md: "430px", sm: '400px', xs: '327px' }, minWidth: "97%",
                                    }} />
                            )}
                        </Box>
                        {product?.Business_Type != "GROCERY" &&

                            loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: 'column',
                                    marginTop: '30px'
                                }}
                            >
                                <Skeleton variant="rectangular" width="92%" height="40px" />
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "35px", marginTop: "5px", padding: "0px 5px 0px 5px" }}>
                                <Autocomplete
                                    onChange={handleUpdateAddress}
                                    name="Address"
                                    label="Address"
                                    size="medium"
                                    fullWidth
                                    variant="outlined"
                                    // clearIcon={null}
                                    options={[...locations, { title: "Add New Address", link: "true" }] || []}
                                    getOptionLabel={(option) => option?.link ? `${option?.title}` : option?.city ? `${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}` : ""}
                                    sx={{ minWidth: { md: "400px", sm: '400px', xs: '300px' }, maxWidth: "100%" }}
                                    renderOption={(props, option) => {
                                        if (option?.link)
                                            return (
                                                <Link href="/accounts/address" {...props} sx={{ textDecoration: "none", }} >{option?.title}</Link>
                                            )
                                        else
                                            return (
                                                <Box  {...props}>
                                                    {`${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}`}
                                                </Box>
                                            )
                                    }}
                                    defaultValue={optionSelected}
                                    value={optionSelected}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder="Select Location..."
                                            {...params}
                                            clearIcon={null}
                                        />
                                    )}
                                />
                                {Type.toUpperCase() === "grocery".toUpperCase() && (partner.ShowError && !product.User_Shipping_Address_Id) &&
                                    <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                        Please select shipping address.
                                    </Typography>}
                                {(!(Type.toUpperCase() === "grocery".toUpperCase()) && !product.User_Shipping_Address_Id)
                                    &&
                                    <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                        Please select shipping address.
                                    </Typography>
                                }
                            </Box>
                        )}
                    </Box>
                </Card>
            </Grid>}
            {
                Type?.toUpperCase() === "RETAIL" &&
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "50%",
                            md: breakPoints.is1600
                                ? "480px" :
                                breakPoints.is1500
                                    ? "33%"
                                    : breakPoints.is1400
                                        ? "30%"
                                        : breakPoints.is1300
                                            ? "30%"
                                            : breakPoints.is1200
                                                ? "40%"
                                                : breakPoints.is1100
                                                    ? "45%"
                                                    : breakPoints.is1000
                                                        ? "50%"
                                                        : breakPoints.is900
                                                            ? "55%"
                                                            : breakPoints.is800
                                                                ? "60%"
                                                                : breakPoints.is700
                                                                    ? "65%"
                                                                    : breakPoints.is600
                                                                        ? "70%"
                                                                        : "75%", // default case if none of the conditions match
                        },
                    }}
                >
                    <Card
                        sx={{
                            maxHeight: "auto",
                            maxWidth: "442px",
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: 2,
                            borderRadius: "12px",
                            border: "2px solid " + theme.palette.primary.main
                        }}
                    >
                        <Box sx={{ width: "100%", maxWidth: "100%", height: '307px', }}>
                            {loading ? (
                                <Box sx={{ width: "100%", maxWidth: "100%", }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <Skeleton variant="rectangular" width="80px" height="60px" />
                                        <Box sx={{ flex: "1 1 auto", marginLeft: "10px" }}>
                                            <Skeleton variant="text" width="80%" height="30px" />
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Skeleton variant="text" width="60%" height="20px" />
                                                <Skeleton variant="circular" width="24px" height="24px" />
                                            </Box>
                                            <Skeleton variant="text" width="40%" height="20px" />
                                        </Box>
                                    </Box>

                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                                    <Box sx={{ width: "80px", height: "60px" }}>
                                        <img
                                            src={product.Product_Image}
                                            alt="Logo"
                                            onClick={() => { navigate(`/retail/products/${product.Product_Id}`) }}
                                            style={{ width: "80px", height: "60px", borderRadius: "6px", objectFit: "fill", cursor: "pointer" }}
                                        />
                                    </Box>
                                    <Box sx={{ flex: "1 1 auto", marginLeft: "10px" }}>
                                        <AutoAdjustText style={{ fontWeight: "bold", color: theme.palette.extra.cardShadowColorOne, fontSize: "18px", fontWeight: "bold" }} numLines={1}>
                                            {product.Product_Name}
                                        </AutoAdjustText>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography>
                                                &#8377; {product.Partner_Selling_Price} * {product.Quantity} = &#8377; {(product.Partner_Selling_Price * product.Quantity).toFixed(2)}
                                            </Typography>
                                            <IconButton onClick={handleDelete}>
                                                <img src={remove} alt="Logo" style={{ width: "24px", height: "24px", objectFit: "fill" }} />
                                            </IconButton>
                                        </Box>
                                        <Typography>
                                            Variant: {product.Size}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}

                            {loading ? (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            gap: '10px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mt: '15px',
                                        }}
                                    >
                                        <Skeleton variant="circular" width="30px" height="30px" />
                                        <Skeleton variant="rectangular" width="30px" height="30px" />
                                        <Skeleton variant="circular" width="30px" height="30px" />
                                    </Box>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '10px' }}>
                                        <Skeleton variant="text" width="60%" height="20px" />
                                    </Box>
                                </>
                            ) : (
                                < Box sx={{ display: "flex", alignItems: "center", padding: "10px", justifyContent: product?.Is_Gift_Wrap_Available === 1 ? "space-between" : "center" }}>
                                    {product?.Is_Gift_Wrap_Available === 1 && (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox checked={showGiftMessage} onChange={handleToggleGiftMessage} />
                                            <Typography sx={{ ml: '8px' }}>Gift Wrap</Typography>
                                        </Box>
                                    )}

                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <Box sx={{ display: "flex", alignItems: "center", mb: '5px' }}>
                                            <IconButton
                                                disabled={product.Quantity === 1 || product.Availability_Stock === 0}
                                                onClick={handleDecrementSize}
                                            >
                                                <img src={minus} alt="Minus Icon" style={{ width: "30px", height: "auto", cursor: "pointer" }} />
                                            </IconButton>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    borderColor: theme.palette.extra.cartBorderColor,
                                                    margin: "0 5px",
                                                }}
                                            >
                                                {product.Quantity}
                                            </Typography>
                                            <IconButton
                                                disabled={product.Quantity === product.Availability_Stock || product.Availability_Stock === 0}
                                                onClick={handleIncrementSize}
                                            >
                                                <img src={plus} alt="Plus Icon" style={{ width: "30px", height: "auto", cursor: "pointer" }} />
                                            </IconButton>
                                        </Box>
                                        {(product.Quantity === product.Availability_Stock || product.Availability_Stock < 20) && (
                                            <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                                Only {product.Availability_Stock} available.
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )}

                            <Box sx={{ marginBottom: "12px", display: "flex", justifyContent: "center" }}>
                                {showGiftMessage && product?.Is_Gift_Wrap_Available === 1 && (
                                    <TextField name="Gift message" label="Gift Message" value={giftMessage} multiline maxRows={3} inputProps={{ maxLength: 300 }}
                                        onChange={handleGiftMessage} onBlur={handleGiftBlur} fullWidth variant="outlined" size="medium" sx={{
                                            width: { md: "430px", sm: '400px', xs: '327px' }, minWidth: "97%",
                                        }} />
                                )}
                            </Box>
                            {product?.Business_Type != "GROCERY" &&

                                loading ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: 'column',
                                        marginTop: '30px'
                                    }}
                                >
                                    <Skeleton variant="rectangular" width="92%" height="40px" />
                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "35px", marginTop: "5px", padding: "0px 5px 0px 5px" }}>
                                    <Autocomplete
                                        onChange={handleUpdateAddress}
                                        name="Address"
                                        label="Address"
                                        size="medium"
                                        fullWidth
                                        variant="outlined"
                                        // clearIcon={null}
                                        options={[...locations, { title: "Add New Address", link: "true" }] || []}
                                        getOptionLabel={(option) => option?.link ? `${option?.title}` : option?.city ? `${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}` : ""}
                                        sx={{ minWidth: { md: "400px", sm: '400px', xs: '300px' }, maxWidth: "100%" }}
                                        renderOption={(props, option) => {
                                            if (option?.link)
                                                return (
                                                    <Link href="/accounts/address" {...props} sx={{ textDecoration: "none", }} >{option?.title}</Link>
                                                )
                                            else
                                                return (
                                                    <Box  {...props}>
                                                        {`${option?.city} - ${option?.street || ""}- ${option?.pincode || ""}`}
                                                    </Box>
                                                )
                                        }}
                                        defaultValue={optionSelected}
                                        value={optionSelected}
                                        renderInput={(params) => (
                                            <TextField
                                                placeholder="Select Location..."
                                                {...params}
                                                clearIcon={null}
                                            />
                                        )}
                                    />
                                    {Type.toUpperCase() === "grocery".toUpperCase() && (partner.ShowError && !product.User_Shipping_Address_Id) &&
                                        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                            Please select shipping address.
                                        </Typography>}
                                    {(!(Type.toUpperCase() === "grocery".toUpperCase()) && !product.User_Shipping_Address_Id)
                                        &&
                                        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                                            Please select shipping address.
                                        </Typography>
                                    }
                                </Box>
                            )}
                        </Box>
                    </Card>
                </Box >

            }
            < Dialog
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setConfirmationOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle >{"Remove item from cart?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to remove this item from your cart?
                    </DialogContentText>
                </DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                    <DialogActions>
                        <Box>
                            <Button onClick={handleDeleteConfirmation} variant="contained" color="primary" autoFocus>
                                delete
                            </Button>
                        </Box>
                        <Box>
                            <Button onClick={handleMoveToWishList} variant="contained" color="primary">
                                Wish List
                            </Button>
                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>

        </>
    );
}

export default CartProductDetails;

